import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { apiGetAuth } from "./../../Util/ApiRequest";
import { ENDPOINTS } from "./../../Util/EndPoint";
import Loader from "./Loader";

import { FaUsers } from 'react-icons/fa';
import { FaScrewdriverWrench, FaUsersGear, FaUsersLine } from "react-icons/fa6";




const HeaderCard = () => {
  useEffect(() => {
    getHeaderData();
  }, []);
  const [loader, setLoader] = useState(false);
  const [headerData, setHeaderData] = useState({});

  const getHeaderData = (url) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.AppHeader,
      (res) => {
        setHeaderData(res)
        setLoader(false);

      },
      (error) => {
        console.log(error)
        setLoader(false);

      }
    );
  };
  return (
    <>
      <Container fluid className="mt-2 card-header-customs">
        <Row>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner  ">
                <div className="header-icons">
                  <FaUsers className="user-img " size={24} />
                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.user_count}</h5>
                  <p className="mb-0 font-12 text-white">Users</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <FaUsersGear className="user-img " size={24} />

                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.normal_count}</h5>
                  <p className="mb-0 font-12 text-white">Normal profiles</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <FaUsersLine className="user-img " size={24} />

                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.alter_ego_count}</h5>
                  <p className="mb-0 font-12 text-white">
                    Alter Ego profiles
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="6" md="12">
            <div className="header-card">
              <div className="card-inner ">
                <div>
                  <FaScrewdriverWrench className="user-img " size={24} />

                </div>
                <div className="ms-4">
                  <h5 className="mb-0 text-white">{headerData.reported_postal_count}</h5>
                  <p className="mb-0 font-12 text-white">
                    Reported posts
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default HeaderCard;
