

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_IMG_URL = process.env.REACT_APP_API_FILES_URL;
export const ENDPOINTS = {
  //User
  UserLogin: `${BASE_URL}api/auth/login`,

  //All Users
  AllUsersWithPagination: `${BASE_URL}api/admin/user/get-all-user`,
  UserActiveDeActiveByAdmin: `${BASE_URL}api/admin/user/active-deactive-user`,
  UserDetailsNormalProfile: `${BASE_URL}api/admin/user/user-detail-normal-profile`,

  //friends
  PaginatedFriends: `${BASE_URL}api/admin/friends/all-friends`,

  //posts
  AllUserPosts: `${BASE_URL}api/admin/user/single-user-posts`,
  PostNormalProfile: `${BASE_URL}api/admin/user/post-detail-normal`,
  PostAlterEgoProfile: `${BASE_URL}api/admin/user/post-detail-alter-ego`,
  PaginatedPost: `${BASE_URL}api/admin/posts-paginated`,



  //reported post
  ReportedPost: `${BASE_URL}api/admin/posts/reported-posts`,
  DeleteReportedPost: `${BASE_URL}api/admin/delete-post`,

  //comments
  PaginatedCommentsPost: `${BASE_URL}api/admin/comments/post-comments`,
  PaginatedLikedPost: `${BASE_URL}api/admin/likes/single-post-likes`,


  // header
  AppHeader: `${BASE_URL}api/admin/user/dashboard-header`,


  //chart
  DashboardChart: `${BASE_URL}api/admin/chart/dashboard-chart`,


  PaginatedBlockUser: `${BASE_URL}api/admin/block-users-paginated`,


  // Comments
  CommentsPaginated: `${BASE_URL}api/admin/comments-paginated`,
  GalleryPaginated: `${BASE_URL}api/admin/gallery-paginated`,

  // Groups
  GroupsPaginated: `${BASE_URL}api/admin/group-paginated`,
  GroupDetail: `${BASE_URL}api/admin/group-detail`,
  GroupMembersPaginated: `${BASE_URL}api/admin/group-members-admins`,

  // Events
  EventsPaginated: `${BASE_URL}api/admin/event-paginated`,
  EventsDetailPaginated: `${BASE_URL}api/admin/event-interested-ongoing`,







};
