import React from 'react';

const BadgeCustom = ({ bgColor, text }) => {
    return (
        <span className={`custom-badge ${bgColor}`}>{text}</span>
    )
}
BadgeCustom.defaultProps = {
    bgColor: "bg-primary"
};
export default BadgeCustom