import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { apiGetAuth } from "../Util/ApiRequest";
import { ENDPOINTS } from "../Util/EndPoint";
import AlterEgoProfileYearChart from "../components/charts/AlterEgoProfileYearChart";
import NormalProfileYearChart from "../components/charts/NormalProfileYearChart";
import ReportedPostsAlterEgo from "../components/charts/ReportedPostsAlterEgo";
import ReportedPostsNormal from "../components/charts/ReportedPostsNormal";
import UsersYearBar from "../components/charts/UsersYearBar";

const Dashboard = () => {
  const [chartData, setChartData] = React.useState([]);

  useEffect(() => {
    getAllUsers()
  }, []);

  const getAllUsers = () => {
    apiGetAuth(
      ENDPOINTS.DashboardChart,
      (res) => {
        setChartData(res);

      },
      (error) => {
        console.log(error, "Users Year Bar")
      }
    );
  };


  return (
    <>
      <Container fluid className="mt-4">
        <Row>
          {chartData?.current_year_users?.length > 0 && (
            <Col md={6} className="mb-4" >
              <Card>
                <UsersYearBar currentYearUsersPro={chartData.current_year_users} />
              </Card>
            </Col>
          )}

          {chartData?.normal_profile_of_year?.length > 0 && (
            <Col md={6} className="mb-4">
              <Card>
                <NormalProfileYearChart NormalProfileYearPro={chartData.normal_profile_of_year} />
              </Card>
            </Col>
          )}
          {chartData?.alter_ego_profile_of_year?.length > 0 && (

            <Col md={6} className="mb-4">
              <Card>
                <AlterEgoProfileYearChart AlterEgoProfileYearPro={chartData?.alter_ego_profile_of_year} />
              </Card>
            </Col>
          )}

          {chartData?.reported_profile_alter_ego?.length > 0 && (

            <Col md={6} className="mb-4">
              <Card>
                <ReportedPostsAlterEgo ReportedPostsAlterEgoPro={chartData?.reported_profile_alter_ego} />
              </Card>
            </Col>
          )}

          {chartData?.reported_profile_normal?.length > 0 && (
            <Col md={6} className="mb-4">
              <Card>
                <ReportedPostsNormal ReportedPostsNormalPro={chartData?.reported_profile_normal} />
              </Card>
            </Col>
          )}
        </Row>
      </Container >
    </>
  );
};

export default Dashboard;
