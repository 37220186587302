import React from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import Footer from '../components/home/footer';
import Copyright from '../components/home/copyright';
import logo from '../assets/images/home/logo.png';
import { useNavigate } from 'react-router-dom';
import LogoCom from '../components/home/LogoCom';
const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <>
      <LogoCom />
      <div className='terms-sect bg-primary'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h1 className='text-white h1-regular  mb-2'>Terms and Conditions</h1>
              <p className='text-white'>LAST UPDATED DECEMBER 19, 2023</p>
              <h2 className='text-white text-center pt-4 pb-4'>TABLE OF CONTENTS</h2>
              <ol className='text-white'>
                <li>AGREEMENT TO TERMS</li>
                <li>INTELLECTUAL PROPERTY</li>
                <li>USER REPRESENTATIONS</li>
                <li>MARKETPLACE OFFERINGS</li>
                <li>PURCHASES AND PAYMENT</li>
                <li>PROHIBITED ACTIVITIES</li>
                <li>USER-GENERATED CONTRIBUTIONS</li>
                <li>CONTRIBUTION LICENSE</li>
                <li>SUBMISSIONS</li>
                <li>THIRD-PARTY WEBSITES AND CONTENT</li>
                <li>SITE MANAGEMENT</li>
                <li>PRIVACY POLICY</li>
                <li>TERM AND TERMINATION</li>
                <li>MODIFICATIONS AND INTERRUPTIONS</li>
                <li>GOVERNING LAW</li>
                <li>DISPUTE RESOLUTION</li>
                <li>CORRECTIONS</li>
                <li>DISCLAIMER</li>
                <li>LIMITATIONS OF LIABILITY</li>
                <li>INDEMNIFICATION</li>
                <li>USER DATA</li>
                <li>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
                <li>MISCELLANEOUS</li>
                <li>CONTACT US</li>
              </ol>
              <h3 className='text-white mt-4'>1. AGREEMENT TO TERMS</h3>
              <p className='text-white'>These Terms of Use form a binding legal agreement between the user,
                either individually or as a representative of an entity (“User”), and HEROSELF
                (“Company”, “We”, “Us”, or “Our”), regarding the User’s access to and use of the HEROSELF’s Website,
                as well as any other related media forms, channels, mobile websites, or mobile applications
                (collectively referred to as the “Site”). The Company is registered in xyz. To maintain a
                secure environment for the purchase and sale of Marketplace Offerings, all Users must accept
                and comply with these Terms of Use. By accessing the Site and/or the Marketplace Offerings,
                the User acknowledges that they have read, understood, and agree to be bound by these Terms
                of Use. If the User does not agree with all of these Terms of Use, they are prohibited from
                using the Site and/or the Marketplace Offerings and must immediately discontinue use.<br />
                Any additional terms and conditions or documents posted on the Site from time to time are
                incorporated into these Terms of Use. The Company reserves the right to make changes or
                modifications to these Terms of Use at any time and for any reason, and will notify the
                User of such changes by updating the “Last updated” date. The User agrees to regularly
                review these Terms of Use to ensure they are aware of any changes. The User’s continued
                use of the Site after any revised Terms of Use have been posted constitutes their acceptance
                of the changes.The information provided on the Site is not intended for distribution or use
                in any jurisdiction or country where such distribution or use would violate laws or regulations,
                or subject the Company to any registration requirements in such jurisdiction or country. Users
                who access the Site from other locations do so at their own risk and are solely responsible for
                compliance with local laws, if applicable.The Site is not designed to comply with industry-specific
                regulations, such as the Health Insurance Portability and Accountability Act (HIPAA) or the Federal
                Information Security Management Act (FISMA). If the User’s interactions are subject to such laws,
                they may not use the Site. The User may not use the Site in a manner that would violate the
                Gramm-Leach-Bliley Act (GLBA).The Site is intended for Users who are at least 13 years of age.
                Users who are minors in their jurisdiction of residence (usually under the age of 18) must obtain
                permission from and be directly supervised by their parent or guardian to use the Site. If the User
                is a minor, their parent or guardian must read and agree to these Terms of Use prior to the User’s
                use of the Site.</p>
              <h3 className='text-white mt-4'>2. INTELLECTUAL PROPERTY RIGHTS</h3>
              <p className='text-white'>The Site and its offerings, unless specified otherwise, are our exclusive property.
                This includes all source code, databases, software, website designs, audio, video, text, photographs, graphics,
                and “Content” on the site, as well as the trademarks, service marks, and logos contained within, referred to as the
                “Marks”. These are protected by various copyright and intellectual property laws, as well as international conventions.
                The Content and Marks are available on the Site for personal use only, “as is”. No part of the Site or its offerings,
                including the Content and Marks, may be used for commercial purposes without our express permission.<br />
                If you meet the eligibility requirements, you’re granted a limited license to access the Site, and to download or print a
                portion of the Content for personal, non-commercial use. All rights not specifically granted to you regarding the Site,
                Content, and Marks remain reserved by us.</p>
              <h3 className='text-white mt-4'>3. USER REPRESENTATIONS</h3>
              <p className='text-white'>By using the Site or the Marketplace Offerings, you confirm and guarantee the following: (1)
                you have the legal capability and agree to follow these Terms of Use; (2) you are over the age of 13; (3) if under the
                age of majority in your jurisdiction, you have obtained parental consent to use the Site; (4) you will not access the
                Site or Marketplace Offerings through automated means or through a bot or script; (5) you will not use the Site for any
                illegal purposes; and (6) your use of the Site or Marketplace Offerings will not contravene any applicable laws or regulations.
                If any information provided by you is found to be untrue, outdated, or incomplete, we have the right to suspend or terminate your
                account and reject any and all future use of the Site. <br />
                You are not permitted to use the Site or Marketplace Offerings for any illegal purposes or to violate any laws, including but not limited to:
                intoxicants, illegal drugs or products, alcohol, gambling, and pornography or adult content. Any postings of prohibited products or content may
                result in the immediate termination of your account and a permanent ban from using the Site. We are a service provider and do not endorse or verify
                the safety, efficiency, adequacy, accuracy, availability, prices, ratings, reviews, or legality of any information on the Site or Marketplace Offerings
                displayed through the Site. The Site’s content is for informational and advertising purposes only and should not be relied upon for accuracy. We cannot
                be held responsible for any errors, omissions, or misrepresentations in the Site’s content.</p>
              <h3 className='text-white mt-4'>4. MARKETPLACE OFFERINGS</h3>
              <p className='text-white'>We are dedicated to accurately representing the colors, features, specifications, and details of the products on our Site, however,
                we cannot make any guarantees in regards to their accuracy, completeness, reliability, timeliness, or freedom from errors. The availability of our products is
                subject to change, and we cannot guarantee that they will always be in stock. Some products may only be available for purchase through our Site and may have
                limited quantities, subject to return or exchange in accordance with our Return Policy. <br /> We retain the right to limit the quantity of products available
                for purchase on our Site and to modify the descriptions and pricing of these products at any time, without prior notice. We also reserve the right to discontinue
                any product offering at our discretion. Additionally, we cannot guarantee that the products you purchase from our Site will meet your expectations or that any
                errors on the Site will be corrected.We cannot guarantee that the products you purchase will meet your expectations or that errors on the Site will be rectified.</p>
              <h3 className='text-white mt-4'>5. PURCHASES AND PAYMENT</h3>
              <p className='text-white'>We accept the following modes of payment: Visa, Mastercard, American Express, Discover, and PayPal. It is your responsibility to provide
                accurate and up-to-date purchase and account information for all orders placed on the Site. This includes keeping your payment information, such as email address
                and payment method, updated to ensure successful transactions and communication from our end. Sales tax may be added to the purchase price as deemed necessary.
                Prices are subject to change without prior notice. Payments are to be made in U.S. dollars. <br /> By placing an order, you agree to pay the current prices,
                including any applicable shipping fees, and authorize us to charge your selected payment provider. We reserve the right to correct any pricing errors.<br />
                We reserve the right to decline any order placed on the Site and to limit the quantity of products ordered per person, per household, or per order.
                This includes orders with the same customer account, payment method, and billing/shipping address, as well as orders that appear to be placed by
                dealers, resellers, or distributors.</p>
              <h3 className='text-white mt-4'>6. PROHIBITED ACTIVITIES</h3>
              <p className='text-white'>As a user of the Site, you must only use it for its intended purpose and cannot use it for any commercial endeavors unless approved by us.
                You must not:</p>
              <ul className='text-white'>
                <li>Systematically collect data or content from the Site without written permission. </li>
                <li>Attempt to deceive or mislead us or other users, especially by trying to access sensitive account information. </li>
                <li>Interfere with security features of the Site or try to bypass any limitations on its use. </li>
                <li>Harm or tarnish the reputation of the Site or us. </li>
                <li>Use information obtained from the Site to harm others. </li>
                <li>Misuse our support services or make false reports. </li>
                <li>Use the Site in an illegal or unregulated manner. </li>
                <li>Engage in unauthorized framing or linking to the Site. </li>
                <li>Upload or transmit viruses, spamming messages, or any material that interferes with the use of the Site. </li>
                <li>Use automated systems, scripts, or data extraction tools. </li>
                <li>Remove the copyright or proprietary rights notice from any content. </li>
                <li>Impersonate another user or use another user’s username. </li>
                <li>Upload or transmit any material that collects information passively or actively. </li>
                <li>Interfere with the functioning of the Site or connected networks. </li>
                <li>Harass, annoy, intimidate, or threaten any employees or agents providing the Marketplace Offerings. </li>
                <li>Bypass measures to prevent access to the Site. </li>
                <li>Copy or adapt the Site’s software code. </li>
                <li>Decipher, decompile, disassemble, or reverse engineer the Site’s software. </li>
                <li>Use automated systems to access the Site or use unauthorized scripts or software. </li>
                <li>Use a buying or purchasing agent to make purchases on the Site. </li>
                <li>Use the Marketplace Offerings for unauthorized commercial purposes, collecting user information for unsolicited emails, or creating false user accounts. </li>
                <li>Use the Site or its content for any revenue-generating endeavors that compete with us. </li>
              </ul>
              <h3 className='text-white mt-4'>7. USER-GENERATED CONTRIBUTIONS</h3>
              <p className='text-white'>The Site does not allow users to post or submit any content. However, you may have the opportunity to publish, distribute, perform, display,
                transmit, or broadcast contributions such as text, videos, photographs, graphics, audio, writings, comments, personal information, and other materials
                (collectively referred to as “Contributions”). Your Contributions may be visible to other users and through third-party websites, and will be treated in
                accordance with the Site Privacy Policy.<br /> By publishing Contributions, you represent and warrant that:</p>
              <ul className='text-white'>
                <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions does not and will not infringe on any third party’s proprietary rights, such as copyright, patent, trademark, trade secret, or moral rights. </li>
                <li>You are the creator and owner of the Contributions, or have the necessary licenses, rights, consents, releases, and permissions to use and allow us, the Site, and other users of the Site to use your Contributions in any manner allowed by the Site and these Terms of Use. </li>
                <li> You have obtained written consent, release, and/or permission from every identifiable individual in your Contributions to use their name or likeness in your Contributions, and to allow the Site and its users to use them in any manner allowed by the Site and these Terms of Use. </li>
                <li> Your Contributions are truthful, not misleading, and do not contain false information. </li>
                <li> Your Contributions are not unsolicited advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation. </li>
                <li>Your Contributions are not offensive, obscene, vulgar, lewd, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable, as determined by us. </li>
                <li>Your Contributions do not ridicule, mock, intimidate, or abuse anyone. </li>
                <li> Your Contributions are not used to harass or threaten any person, and do not promote violence against any person or group. </li>
                <li> Your Contributions comply with all applicable laws, regulations, and rules. </li>
                <li>Your Contributions do not violate any third party’s privacy or publicity rights. </li>
                <li>Your Contributions do not violate any laws related to child pornography or any laws aimed at protecting the health or well-being of minors. </li>
                <li> Your Contributions do not include offensive comments based on race, national origin, gender, sexual preference, or physical handicap. </li>
                <li> Your Contributions do not violate any provisions of these Terms of Use, or any applicable laws or regulations. </li>
              </ul>
              <p className='text-white'>Any violation of these requirements may result in the termination or suspension of your rights to use the Site
                and the Marketplace Offerings.</p>
              <h3 className='text-white mt-4'>8. CONTRIBUTION LICENSE</h3>
              <p className='text-white'>You and the Site have agreed that any information and personal data you provide may be accessed, stored, processed,
                and used in accordance with the Privacy Policy and your preferences. If you provide suggestions or feedback about the Site, you agree that it
                may be used and shared without compensation. The Site does not claim ownership of your Contributions. You retain complete ownership and all
                related intellectual property or proprietary rights. The Site is not responsible for the content or accuracy of your Contributions and you
                agree to absolve the Site from any liability or potential legal action.</p>
              <h3 className='text-white mt-4'>9. SUBMISSIONS</h3>
              <p className='text-white'>By agreeing to these terms, you understand that any feedback, ideas, or information you provide regarding the
                Site or its offerings will be considered non-confidential and the property of the Site. The Site has the exclusive right to use and distribute
                these submissions without compensating you and without acknowledging you as the source. You confirm that the submissions are original or
                that you have the right to submit them and agree to not take legal action against the Site for any potential infringement of proprietary rights
                related to these submissions. Additionally, you forfeit your moral rights to these submissions.</p>
              <h3 className='text-white mt-4'>10. THIRD-PARTY WEBSITES AND CONTENT</h3>
              <p className='text-white'>The Site or Marketplace Offerings may include links to other websites (known as “Third-Party Websites”) and content
                that belongs to third parties, such as articles, photographs, text, graphics, music, video, and software (collectively referred to as “Third-Party Content”).
                We do not endorse or assume responsibility for the accuracy, quality, or completeness of Third-Party Websites or Third-Party Content accessed through the Site
                or posted on it. If you choose to access Third-Party Websites or use Third-Party Content, you do so at your own risk and are subject to the terms and policies
                of those websites, which may differ from these Terms of Use. Any purchases made through Third-Party Websites are solely between you and the third party, and we
                bear no responsibility for such purchases. You agree to hold us harmless from any harm or losses resulting from your use of Third-Party Websites or Third-Party Content.</p>
              <h3 className='text-white mt-4'>11. SITE MANAGEMENT</h3>
              <p className='text-white'>We reserve the right, but not the obligation, to (1) monitor the Site for violations of these Terms of Use: (2) take appropriate legal action
                against anyone who, in our sole discretion. violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities.
                (3) in our sole discretion and without limitation. refuse. restrict access to. limit the availability of. or disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof. (4) in our sole discretion and without limitation. notice, or liability, to remove from the Site or otherwise disable all files and
                content that are excessive in size or are in any way burdensome to our systems: and (5) otherwise manage the Site in a manner designed to protect our rights and property
                and to facilitate the proper functioning of the Site and the Marketplace Offerings.</p>
              <h3 className='text-white mt-4'>12. PRIVACY POLICY</h3>
              <p className='text-white'>We value data privacy and security. Please read our Privacy Policy. By using the Site or the Marketplace Offerings, you agree to follow our
                Privacy Policy, which is part of these Terms of Use. Please note that the Site and the Marketplace Offerings are located in the xyz. If you use the Site or the Marketplace
                Offerings from a region with different data privacy laws, your data will be transferred to the xyz when you use the Site and you consent to having your data processed in the xyz.
                Additionally, we do not knowingly collect or market to children under the age of 13, in accordance with the U.S. Children’s Online Privacy Protection Act. If we become aware
                that a child under the age of 13 has provided us with personal information without proper parental consent, we will promptly remove that information from the Site.</p>
              <h3 className='text-white mt-4'>13. TERM AND TERMINATION</h3>
              <p className='text-white'>These Terms of Use will remain valid while you use the Site. We reserve the right, in our sole discretion and without notice or liability, to deny
                access to and use of the Site and Marketplace Offerings to anyone, for any reason or no reason, including, but not limited to, a violation of these Terms of Use or any applicable
                law or regulation. We may also terminate your use of the Site and Marketplace Offerings, or delete any content you have posted, at any time, without warning. <br /> If we end your
                account for any reason, you are forbidden from registering a new account using your own name, a fake name, or someone else’s name, even if you are acting on their behalf.
                In addition to ending your account, we may take appropriate legal action, such as pursuing civil, criminal, or injunctive remedies. </p>
              <h3 className='text-white mt-4'>14. MODIFICATIONS AND INTERRUPTIONS</h3>
              <p className='text-white'>We reserve the right to alter, modify, or remove any information from the Site at any given time, without the need for prior notice, at our discretion.
                Nevertheless, we are not obligated to update any information on the Site. We can also choose to alter or discontinue parts or all of the Marketplace Offerings without notice at any time.
                Any changes, price changes, interruptions, suspensions, or discontinuance of the Site or the Marketplace Offerings will not result in liability to you or any third party.<br />  We cannot
                guarantee constant availability of the Site or the Marketplace Offerings, as we may encounter hardware or software issues, or require maintenance on the Site, leading to delays, interruptions,
                or errors. We retain the right to revise, change, update, or discontinue the Site or the Marketplace Offerings at any time without notice to you. If the Site or the Marketplace Offerings are
                unavailable during downtime, maintenance, or discontinuance, we will not be held liable for any loss, inconvenience, or damage caused to you. The terms of use do not obligate us to maintain
                and support the Site or the Marketplace Offerings, nor do they require us to provide any updates, corrections, or releases related to them. </p>
              <h3 className='text-white mt-4'>15. GOVERNING LAW</h3>
              <p className='text-white'>The laws of the State of xyz shall govern these Terms of Use and your use of the Site and the Marketplace Offerings. These agreements shall be interpreted and enforced
                in accordance with the laws of xyz, regardless of any conflicting legal principles.</p>
              <h3 className='text-white mt-4'>16. DISPUTE RESOLUTION</h3>
              <p className='text-white'>These Terms of Use dictate that any legal disputes between you and us must be resolved in the state and federal courts located in xyz. Both parties agree to this jurisdiction
                and waive any objections to personal jurisdiction and the inconvenience of the forum. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information
                Transaction Act (UCITA) does not apply to this agreement.</p>
              <h3 className='text-white mt-4'>17. CORRECTIONS</h3>
              <p className='text-white'>The Site may contain typographical errors, inaccuracies, or omissions in information related to the Marketplace Offerings, including but not limited to descriptions, pricing,
                availability, and other details. We retain the right to rectify any inaccuracies, errors, or omissions, and to update the information on the Site without any prior notice at our discretion.</p>
              <h3 className='text-white mt-4'>18. DISCLAIMER</h3>
              <p className='text-white'>The Site and Marketplace Offerings are provided as is, and availability may vary. You acknowledge that using the Site and our services is at your own risk. We reject any warranty,
                whether express or implied, related to the Site and Marketplace Offerings and your use of them, including but not limited to, the warranty of merchantability, suitability for a specific purpose, and non-infringement.
                We cannot guarantee the accuracy or completeness of the Site’s content, or content found on linked websites. We cannot be held responsible for any errors, inaccuracies, or omissions in the content, personal or property
                damage caused by using the Site, unauthorized access to our secure servers, or the loss or damage of personal or financial information stored there.<br /> We will not be held accountable for interruptions in transmission
                to or from the Site or Marketplace Offerings, bugs or viruses transmitted to the Site by third parties, or errors or omissions in the content. We do not endorse or take responsibility for any product or service advertised
                or offered by third parties through the Site or linked websites. <br /> When purchasing products or services through any medium, use caution and make informed decisions. We are not responsible for monitoring transactions
                between you and third-party providers. </p>
              <h3 className='text-white mt-4'>19. LIMITATIONS OF LIABILITY</h3>
              <p className='text-white'>In the event of any loss or damage resulting from your use of the Site or the Marketplace Offerings, neither we nor any of our directors, employees, or agents will be held responsible for any direct,
                indirect, incidental, special, punitive, or exemplary damages, including but not limited to lost profits, lost revenue, loss of data, or any other damages. Our liability to you in any form of legal action will be limited to the
                amount you have paid to us in the preceding 6 months, if any. Please note that some state and international laws do not permit limitations on warranties or the exclusion or limitation of certain damages. If such laws apply to you,
                some or all of the above limitations may not apply and you may have additional rights.</p>
              <h3 className='text-white mt-4'>20. INDEMNIFICATION</h3>
              <p className='text-white'>You agree to protect and compensate us and our related entities, including subsidiaries and affiliates, as well as all officers, agents, partners, and employees, from any loss, damage, liability, or legal
                demand, including any fees for attorneys and expenses, brought forth by a third party due to:</p>
              <ol className='text-white'>
                <li>Your use of the Marketplace Offerings;</li>
                <li>Any violation of these Terms of Use;</li>
                <li>Any false representation made by you in these Terms of Use;</li>
                <li>Your infringement on the rights of others, including intellectual property rights</li>
                <li>Any harmful actions towards other users of the Site and Marketplace Offerings who you connected with via the Site.</li>
              </ol>
              <p className='text-white'>However, we have the right to take charge and control of any matter, at your expense, that requires your indemnification and you must cooperate and cover the cost of defense. We will make reasonable attempts to
                inform you of any such legal matter as soon as we become aware of it.</p>
              <h3 className='text-white mt-4'>21. USER DATA</h3>
              <p className='text-white mt-4'>As a user of the Marketplace Offerings, we’ll keep track of certain information you provide to the Site. This information helps us manage the performance of the Marketplace Offerings, as well as your usage of
                them. However, despite our regular backup routines, you are responsible for all data that you submit or that relates to your activities using the Marketplace Offerings. Unfortunately, we cannot be held liable for any loss or damage to this
                data and you agree to give up your right to take any legal action against us for such loss or corruption.</p>
              <h3 className='text-white mt-4'>22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h3>
              <p className='text-white'>By visiting the website, sending emails, and completing online forms, you are engaging in electronic communication. You hereby give your consent to receive communications electronically and acknowledge that all
                agreements, notices, disclosures, and other communications provided to you in this manner, via email or the website, meet the legal requirement for written communication. Additionally, you agree to use electronic signatures, contracts,
                and records and to receive electronic delivery of notices, policies, and records of transactions initiated or completed by us or through the website. You also waive any rights or requirements under any laws that demand an original signature
                or physical record-keeping and non-electronic payments or credits.</p>
              <h3 className='text-white mt-4'>23. MISCELLANEOUS</h3>
              <p className='text-white'>These Terms of Use, together with any policies or operating rules posted on the website or pertaining to the Marketplace Offerings, represent the complete agreement between you and us. Our inaction or failure to
                enforce any right or provision listed in these Terms of Use should not be interpreted as a waiver of such right or provision. These Terms of Use are valid and enforceable to the fullest extent permitted by law. We reserve the right to transfer
                any or all of our rights and obligations to others at any time. We will not be held responsible for any loss, damage, delay, or failure to act caused by circumstances beyond our reasonable control.<br /> Should any provision or part of a provision
                of these Terms of Use be deemed unlawful, void, or unenforceable, that specific provision or part of the provision will be separated from the remainder of these Terms of Use and will not affect the validity and enforceability of the remaining provisions.
                Please note that these Terms of Use do not create a joint venture, partnership, employment, or agency relationship between you and us. You agree that these Terms of Use will not be interpreted against us because we drafted them. Additionally, you hereby
                waive any and all defenses you may have related to the electronic form of these Terms of Use and the lack of physical signatures by the parties. </p>
              <h3 className='text-white mt-4'>24. CONTACT US</h3>
              <p className='text-white'>To resolve any issues or get more information about the Site or Marketplace Offerings, please don’t hesitate to contact us. Our team is dedicated to providing support and ensuring a positive experience for all users. We are here
                to assist with any questions or concerns you may have. Please reach out to us for prompt and efficient resolution of your needs.</p>
              <ul className='icon-list'>
                <li>
                  <a href="mailto:support@heroselfapp.com" className='text-white a-decuration-text'>
                    <span className='text-secondary font-18 me-3'><FaEnvelope /></span>
                    <span>support@heroselfapp.com</span>
                  </a>
                </li>
                <li>
                  <a href="#" target='_blank' className='text-white a-decuration-text'>
                    <span className='text-secondary font-18 me-3'> <FaMapMarkerAlt /></span>
                    <span>15955 Formosa Lane Brandywine MD 20613</span></a>
                </li>
                <li>
                  <a href="tel:+1240-640-7386" target='_blank' className='text-white a-decuration-text'>
                    <span className='text-secondary font-18 me-3'><FaPhoneAlt /></span>
                    <span>+1240-640-7386</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />


    </>
  )
}

export default TermsAndConditions
