import React from "react";
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS } from "../Util/EndPoint";
import TableComponent from "../components/global/TableComponent";
import UserAvatarNormal from "../components/global/UserAvatarNormal";
import { ProfileTypeEnum } from "../Util/Helper";

const BlockUserNormal = () => {
    const navigate = useNavigate();

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: false,
            width: '60px'
        },
        {
            name: 'Block By',
            selector: (row) => <UserAvatarNormal user={row?.user_block_by} />,
            sortable: false,
        },

        {
            name: 'Block To',
            selector: (row) => <UserAvatarNormal user={row?.user_block_to} />,
            sortable: false,
        },

    ];


    return (
        <>

            <TableComponent
                title="User Block Normal"
                endPoint={ENDPOINTS.PaginatedBlockUser}
                headers={columns}
                searchObject={{ profile_type: ProfileTypeEnum.NormalProfile }}

            />
        </>
    )
}
export default BlockUserNormal;
