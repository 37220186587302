import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/home/logo.png';

const LogoCom = () => {
    const navigate = useNavigate();

    return (
        
        <div className='container position-relative'>
            <div className='row'>
                <div className='col-12'>
                    <div className='logo-sect'>
                        <img onClick={() => navigate(`/`)} src={logo} alt="Logo" />
                    </div>
                </div>
            </div>
        </div>
    
    )
}

export default LogoCom
