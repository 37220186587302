import moment from "moment";
import React from "react";
import { BASE_IMG_URL } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";

function FriendAlterEgo ({ itemPro }) {
  return (
    <>
      <div className="d-flex pt-6 comment-boxes">
        <div className="symbol symbol-45px me-2">
          <img src={itemPro?.profile_alter_ego?.alter_ego_profile_meta?.profile_image ? BASE_IMG_URL + itemPro?.profile_alter_ego?.alter_ego_profile_meta : CatImg} alt="profile" className='border border-primary' />
        </div>
        <div className="d-flex flex-column flex-row-fluid">
          <div className="align-items-center flex-wrap mb-0">
            <h6 className="text-gray-800 mb-0 me-2">{itemPro?.profile_alter_ego?.user_name}</h6>
            <span className="text-gray-400 font-10">{moment(itemPro?.created_at).format("YYYY-MM-DD hh:mm:ss a")}</span>
          </div>
        </div>
      </div>
    </>

  );
}

export default FriendAlterEgo;
