import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import CustomTooltip from "./CustomTooltip";
import Loader from "./Loader";


const MyPosts = ({ profileTypePro, userIdPro }) => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);
  const [mediaModal, setMediaModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getAllPostsWithPagination(url);
  }, []);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllPostsWithPagination(url);
  };

  const getAllPostsWithPagination = (url) => {
    let body = {
      user_id: userIdPro,
      profile_type: profileTypePro,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.AllUserPosts + url,
      body,
      (res) => {
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto p-2">Posts</h5>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Post id</th>
                <th>Text</th>
                <th>Activity</th>
                <th>For</th>
                <th>Check in</th>
                <th>Posted</th>
                <th>Comments count</th>
                <th>Like count</th>
                <th>Share count</th>
                <th>Detail</th>

              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>
                    <td className="position-relative">
                      <p className="mb-0 ">{item?.id}</p>
                    </td>

                    <td>
                      <CustomTooltip descriptionPro={item?.post_text} />
                    </td>
                    <td className="position-relative">
                      <p className="mb-0 ">{item?.post_activity}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0 ">{item?.post_for}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0 ">{item?.check_in}</p>
                    </td>
                    <td>{moment(item?.created_at).format("YYYY-MM-DD hh:mm:ss a")}</td>

                    <td className="position-relative">
                      <p className="mb-0 ">{item?.post_comments_count ? item?.post_comments_count?.post_comments_count : 0}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0 ">{item?.post_like_count ? item?.post_like_count?.post_like_count : 0}</p>

                    </td>
                    <td className="position-relative">
                      <p className="mb-0 ">{item?.post_share_count ? item?.post_share_count?.post_share_count : 0}</p>
                    </td>
                    <td className="position-relative">
                      {profileTypePro == "normal_profile" && (
                        <button className="btn btn-primary" onClick={() => {
                          window.location.href = `/admin/post-detail-normal?user_id=${userIdPro}&post_id=${item.id}`;
                        }}
                        >detail</button>
                      )}
                      {profileTypePro == "alter_ego_profile" && (
                        <button className="btn btn-primary" onClick={() => {
                          window.location.href = `/admin/post-detail-alter-ego?user_id=${userIdPro}&post_id=${item.id}`;
                        }}
                        >detail</button>
                      )}

                    </td>
                  </tr>
                ))}
            </tbody>

          </Table>
          {items?.length === 0 && (
            <h6 className='text-center mt-2'>posts not found</h6>
          )}
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default MyPosts;
