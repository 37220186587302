import React, { useEffect } from 'react'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import Footer from '../components/home/footer';
import Copyright from '../components/home/copyright';
import logo from '../assets/images/home/logo.png';
import { useNavigate } from 'react-router-dom';
import LogoCom from '../components/home/LogoCom';



const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <LogoCom />
      <div className='privacy-sect bg-primary'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h1 className='text-white h1-regular text-center'>Privacy Policy</h1>
              <p className='text-white'>LAST UPDATED: 19-12-2023</p>
              <p className='text-white'>In this Privacy Policy, learn how <strong>HEROSELF</strong> manages your <strong>Personal Information</strong>. If you have any questions
                pertaining to the contents of this Privacy Policy, you are welcome to get in touch with us.</p>
              <h3 className='text-white mt-4'>Privacy Policy</h3>
              <p className='text-white'>This Privacy Policy expounds on the specifics of the processes where <strong>HEROSELF</strong> handles your personal data or personal information. In the
                case where it is not your intention for <strong>HEROSELF</strong> to collect, use, or save your Personal Information, you are requested to refer to our Contact Page to speak
                to a representative. <br /> Within this Privacy Policy, “Personal Information”, or “Personal Data”, refers to the information that can be used to distinguish your identity. Such information can be your: “Full Name”, “Mailing Address”, “Device Identifiers”, “E-mail Address”, “Contact Details”, and “Date of Birth”.
                “HEROSELF”, “Us”, and “We” may refer to the website or app of HEROSELF. </p>
              <h3 className='text-white mt-4'>COLLECTING YOUR PERSONAL DATA</h3>
              <p className='text-white'>The application of this Privacy Policy extends to the individuals who participate in the usage of <strong>HEROSELF</strong>.
                Users and consumers of <strong>HEROSELF</strong> are requested to share their Personal Data upon registration. <br /> As mentioned under the first section
                of this Privacy Policy, “Personal Data”, includes your “Full Name”, “Mailing Address”, “Device Identifiers”, “E-mail Address”, “Contact Details”, and “Date of Birth”. <br />
                Personal Data is collected through various methods of registration, and can be conducted through:</p>
              <ol className='text-white'>
                <li>The forms present on HEROSELF’s site,</li>
                <li> Phone conversations with the representatives of HEROSELF,</li>
              </ol>
              <p className='text-white'>Through the above-mentioned ways of collecting Personal Data, HEROSELF gathers the information related to you to process your information
                and fulfill your requests.</p>
              <h3 className='text-white mt-4'>Personal Data Collected Through Technology</h3>
              <p className='text-white'>Some of your Personal Data is collected through different online or technological sources.</p>
              <p className='text-white'>When you access or contact HEROSELF’s or its Third-Party service providers, Personal Data and your interaction behavior are recorded.</p>
              <h3 className='text-white mt-4'>Log Data</h3>
              <p className='text-white'>HEROSELF wishes to inform you that whenever you visit our website, in the case of an error we collect data and information (through third parties)
                on your phone called Log Data. This Log Data may include information such as your device’s Internet Protocol (“IP”) address, device name, operating system version, when
                utilizing HEROSELF, the time and date of your use of HEROSELF, and other statistics. <br /> To help you understand Log Data better, we have elaborated on the details below: </p>
              <ul className='text-white'>
                <li>Location Data may be collected through APIs like Google Analytics, to achieve a smooth and functional provision of intended services.</li>
                <li>When you access the website of HEROSELF, your Internet Protocol (IP) Address is collected.</li>
              </ul>
              <p className='text-white'>An IP address is associated with the utilized portal to access the Internet. This may include information like your Internet Service Provider (ISP), Company,
                or information about your Institution. While an IP Address may reveal your ISP or Geographic Area, your identity cannot be solely determined by your IP address. The collection of your
                IP Address, its storage, and its use is strictly treated in accordance with US Federal Law.</p>
              <ul className='text-white'>
                <li>We may also collect your device’s unique identifier (“Device Identifier”). This helps us identify the type of device that was used to access HEROSELF.</li>
              </ul>
              <p className='text-white'>A Device Identifier is a number that is automatically assigned to the device you used to access HEROSELF . We may link the Device Identifier to other information about your visit,
                such as your activity on HEROSELF .</p>
              <p className='text-white'>Cookies are collected by HEROSELF with your consent. If you do not approve of Cookies being collected by our platform, or by our third-party service providers, simply reject the
                generated request upon your visit to HEROSELF.</p>
              <h3 className='text-white mt-4'>WHY DO WE COLLECT YOUR PERSONAL DATA?</h3>
              <p className='text-white'>When necessary, Personal Data is used or stored within HEROSELF’s databases to fulfill your requests.
                All Personal Data or Personal Information may also be used to improve your experience at HEROSELF.
                Here are some of the measures we take to fulfill your requests and enhance your HEROSELF experience:</p>
              <ul className='text-white'>
                <li>Scheduling, executing, or canceling your requests.</li>
                <li>Reviewing registered users.</li>
                <li>Providing public user profiles.</li>
                <li>Addressing service complaints.</li>
                <li>Communication over telephone, e-mail, or text messages.</li>
                <li>Enabling Customer Service Professionals to assist you.</li>
                <li>Marketing and promotional material, on behalf of HEROSELF or selected third parties, unless you have chosen not to subscribe.</li>
                <li>Troubleshooting technical problems at HEROSELF.</li>
                <li>Responding to your queries and receiving your feedback.</li>
                <li>Conducting research and analysis.</li>
                <li>Evaluating and improving the user’s experience.</li>
                <li>Preventing, discovering, and investigating violations of this Privacy Policy or any applicable terms of service and investigating fraud, chargebacks, or other matters.</li>
                <li>Contacting you in regards to your activity on HEROSELF, and, at our discretion, changes to HEROSELF and/or HEROSELF’s</li>
              </ul>
              <p className='text-white'>Moreover, to comply with the law or to protect the rights, property, or safety of HEROSELF and its users. <br />
                Network maintenance and information security, for fraud prevention, and to report suspected criminal activity.</p>
              <p className='text-white'>When using HEROSELF, you expressly grant consent to receive communications from us. We may communicate with you
                via e-mail, text message, calls, and push notifications through your provided Personal Data. <br /> You agree that all approvals concerning
                the use of your Personal Data will persist even after the cancellation of your account on our platform. In order to change these terms, kindly
                get in touch with our Customer Service Professionals.</p>
              <p className='text-white'>Moreover, you acknowledge that HEROSELF or its Third-Party service providers may record customer service calls after notifying you,
                in order to assist you when you contact our Customer Support Representatives. <br /> The information described in this Section may be transferred to,
                or processed and stored in, the United States or other countries, which may employ greater or less restrictive data protection laws. <br />  In all cases, we will
                take appropriate measures to protect your Personal Data, in accordance with the policies described herein.If you have any questions regarding the information
                provided in this Section. Kindly get in touch with us so we can assist you better.</p>
              <h3 className='text-white mt-4'>HOW IS YOUR PERSONAL DATA SHARED?</h3>
              <p className='text-white'>As explained in the earlier sections of this Privacy Policy, the user’s Personal Data is primarily collected and stored to fulfill the user’s requests.
                Personal Data used to create user profiles on HEROSELF will be shared with the professionals responsible for handling the user’s requests.We may directly fulfill your order
                requests or form associations with trusted third-party partners for the fulfillment of your order requests.</p>
              <h3 className='text-white mt-4'>Third-Parties Associated with HEROSELF</h3>
              <p className='text-white'>There are many Third-Party Service Providers associated with HEROSELF. For the primary purpose of furnishing the website experience, and fulfilling your
                order requests, HEROSELF may provide the Third-Party Service Providers with your Personal Data. Other secondary processes to support the execution of your requested service(s),
                are elaborated as follows.</p>
              <ul className='text-white'>
                <li>E-mail Delivery Systems will be utilized to communicate with you via e-mails.</li>
                <li>Customer Support Representatives will receive your Personal Data to respond to your queries and to verify your identity to make amendments to your Personal Data upon your request.</li>
                <li>We reserve the right to disclose and transfer your Personal Data:</li>
              </ul>
              <p className='text-white'>To a subsequent owner, co-owner, or operator of HEROSELF. <br /> In the case where there is a corporate merger, consolidation, or the sale of substantially all of
                our membership interests and/or assets. </p>
              <h3 className='text-white mt-4'>PROTECTING YOUR PERSONAL DATA</h3>
              <p className='text-white'>HEROSELF sincerely takes every step to preserve your right to privacy.  <br /> Your Personal Data is encrypted to prevent any accidental, unauthorized,
                or unlawful access and processing. These measures are undertaken to uphold the confidentiality of your identity and your Personal Data.  <br />  Moreover, your Personal Data is
                also protected in accordance with Federal and State Data Protection legislation. In extension to these measures, HEROSELF, legally, does not reserve any right over the contents
                of your Personal Information or Personal Data. In the case where you need to withdraw consent, make amendments, or completely remove HEROSELF’s ability to access your Personal
                Data, kindly get in touch with us through the Contact Information provided at the end of the Policy.</p>
              <h3 className='text-white mt-4'>EXTERNAL LINKS ON HEROSELF</h3>
              <p className='text-white'>External Links may also be present on HEROSELF. If you click on a third-party link, you will be directed to that address. Note that these external
                sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the
                content, privacy policies, or practices of any third-party sites or services.</p>
              <h3 className='text-white mt-4'>CHILDREN’S PRIVACY</h3>
              <p className='text-white'>In case you are under the age of 13 and intend to use HEROSELF, you are advised to do so under parental guidance. In the case we discover that a
                child under 13 has provided us with their Personal Data, we immediately eradicate the information from our databases. If you are a parent or guardian and you are aware that
                your child has provided us with Personal Data, kindly get in touch with us, so that we will be able to execute the necessary actions concerning your child’s Personal Data.</p>
              <ul className='icon-list'>
                <li>
                  <a href="mailto:support@heroselfapp.com" className='text-white a-decuration-text'>
                    <span className='text-secondary font-18 me-3'><FaEnvelope /></span>
                    <span>support@heroselfapp.com</span>
                  </a>
                </li>
                <li>
                  <a href="#" target='_blank' className='text-white a-decuration-text'>
                    <span className='text-secondary font-18 me-3'> <FaMapMarkerAlt /></span>
                    <span>15955 Formosa Lane Brandywine MD 20613</span></a>
                </li>
                <li>
                  <a href="tel:+1240-640-7386" target='_blank' className='text-white a-decuration-text'>
                    <span className='text-secondary font-18 me-3'><FaPhoneAlt /></span>
                    <span>+1240-640-7386</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export default PrivacyPolicy
