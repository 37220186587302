import React from "react";
import { Player } from "video-react";

const HtmlVideo = ({ urlPro, urlThumbnailPro }) => {
  return (
    <div>
      <Player playsInline poster={urlThumbnailPro} src={urlPro} />
    </div>
  );
};

export default HtmlVideo;
