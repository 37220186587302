import moment from "moment";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS } from "../Util/EndPoint";
import { ProfileTypeEnum } from "../Util/Helper";
import CustomTooltip from "../components/global/CustomTooltip";
import TableComponent from "../components/global/TableComponent";


const GroupsAlterEgo = () => {

    const navigate = useNavigate();
    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: false,
            width: '60px'
        },
        {
            name: 'Name',
            selector: (row) => row?.group_name,
            sortable: false,
        },
        {
            name: 'Privacy',
            selector: (row) => row?.privacy,
            sortable: false,
        },
        {
            name: 'Description',
            selector: (row) => <CustomTooltip descriptionPro={row?.description} />,
            sortable: false,
            wrap: true,
            maxWidth: '250px',
            minWidth: '200px'
        },
        {
            name: 'Created At',
            selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
            sortable: false,
        },

        {
            name: 'Detail',
            selector: (row) => (
                <button className="btn btn-primary" onClick={() => {
                    navigate(`/admin/group-detail-alter-ego/${row?.id}`);
                }}>
                    detail
                </button>
            ),
            sortable: false,
        },
    ];


    return (
        <>

            <TableComponent
                title="Groups AlterEgo"
                endPoint={ENDPOINTS.GroupsPaginated}
                headers={columns}
                searchObject={{ profile_type: ProfileTypeEnum.AlterEgoProfile }}

            />
        </>
    )
}
export default GroupsAlterEgo;
