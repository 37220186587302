import BootstrapSwitchButton from "bootstrap-switch-button-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { apiGetAuth, apiPost } from "../Util/ApiRequest";
import { ENDPOINTS } from "../Util/EndPoint";
import Loader from "../components/global/Loader";

const Users = () => {
  const [userSearch, setUserSearch] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);

  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getAllUsersWithPagination(url);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllUsersWithPagination(currentUrl);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [userSearch]);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllUsersWithPagination(url);
  };

  const searchUsersByText = (name) => {
    setUserSearch(name);
    if (currentUrl) {
      setCurrentUrl(currentUrl + "&search_user=" + name);
      getAllUsersWithPagination(currentUrl + "&search_user=" + name);
    }
    setCurrentUrl("?search_user=" + name);
    getAllUsersWithPagination("?search_user=" + name);
    setTimeout();
  };

  const getAllUsersWithPagination = (url) => {
    setLoader(true);
    apiGetAuth(
      ENDPOINTS.AllUsersWithPagination + url,
      (res) => {
        setLoader(false);
        setItem(res?.results);
        setTotalPage(res?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  const activeDeActiveUsers = (id) => {
    const body = { user_id: id };

    apiPost(
      ENDPOINTS.UserActiveDeActiveByAdmin,
      body,
      (res) => {
        console.log(res);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <div>
        <div className="user-search mb-3">
          <label className="my-auto me-2">User Search</label>
          <Form.Control
            type="search"
            placeholder="Search User"
            className="me-2 users-search"
            aria-label="Search"
            value={userSearch}
            onChange={(e) => searchUsersByText(e.target.value)}
          />
        </div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto p-2">Users</h5>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>User id</th>
                <th>Email</th>
                <th>Account created</th>
                <th>Status</th>
                <th>Normal profile</th>
                <th>Alter ego profile</th>
              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>

                    <td className="position-relative">
                      <p className="mb-0">{item?.id}</p>
                    </td>
                    <td className="position-relative">
                      <p className="mb-0">{item?.email}</p>
                    </td>
                    <td>{moment(item?.created_at).format("LL")} </td>

                    <td>
                      <BootstrapSwitchButton
                        size="sm"
                        checked={item?.is_block == 0 ? false : true}
                        onlabel="Active"
                        offlabel="De-active"
                        onChange={() => activeDeActiveUsers(item?.id)}
                      />
                    </td>
                    <td>
                      {item?.profile_normal != null && (
                        <a href={`user-detail-normal/${item.id}`} className="btn btn-secondary" > Details </a>
                      )}
                      {item?.profile_normal == null && (
                        <span className="text-white p-2 bg-danger rounded">Not created </span>
                      )}

                    </td>
                    <td>
                      {item?.profile_alter_ego != null && (
                        <a
                          href={`user-detail-alter-ego/${item.id}`}
                          className="btn btn-secondary"
                        > Details</a>
                      )}
                      {item?.profile_alter_ego == null && (
                        <span className="text-white p-2 bg-danger rounded">Not created </span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default Users;
