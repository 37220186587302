import moment from "moment";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS } from "../Util/EndPoint";
import { ProfileTypeEnum } from "../Util/Helper";
import CustomTooltip from "../components/global/CustomTooltip";
import TableComponent from "../components/global/TableComponent";
import UserAvatarNormal from "../components/global/UserAvatarNormal";


const EventNormal = () => {

    const navigate = useNavigate();

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: false,
            width: '60px'
        },
        {
            name: 'Creator',
            selector: (row) => <UserAvatarNormal user={row?.creator} />,
            sortable: false,
            wrap: true,
            maxWidth: '400px',
            minWidth: '250px'
        },
        {
            name: 'Name',
            selector: (row) => row?.event_name,
            sortable: false,
        },
        {
            name: 'Venue',
            selector: (row) => <CustomTooltip descriptionPro={row?.event_venue_name} />,
            sortable: false,
            wrap: true,
            maxWidth: '250px',
            minWidth: '200px'
        },
        {
            name: 'Description',
            selector: (row) => <CustomTooltip descriptionPro={row?.description} />,
            sortable: false,
            wrap: true,
            maxWidth: '250px',
            minWidth: '200px'
        },

        {
            name: 'Longitude',
            selector: (row) => row?.longitude,
            sortable: false,
        },
        {
            name: 'Latitude',
            selector: (row) => row?.latitude,
            sortable: false,
        },
        {
            name: 'Public',
            selector: (row) => row?.is_public == 1 ? "Yes" : "No",
            sortable: false,
        },


        {
            name: 'Event Date',
            selector: (row) => moment(row?.event_date).format("YYYY-MM-DD hh:mm:ss a"),
            sortable: false,
        },
        {
            name: 'Created At',
            selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
            sortable: false,
        },
        {
            name: 'Detail',
            selector: (row) => (
                <button className="btn btn-primary" onClick={() => {
                    navigate(`/admin/event-detail-normal/${row?.id}`);
                }}>
                    detail
                </button>
            ),
            sortable: false,
        },
    ];


    return (
        <>

            <TableComponent
                title="Events Normal"
                endPoint={ENDPOINTS.EventsPaginated}
                headers={columns}
                searchObject={{ profile_type: ProfileTypeEnum.NormalProfile }}

            />
        </>
    )
}
export default EventNormal;
