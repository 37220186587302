import React from "react";
import Avatar from 'react-avatar';
import { BASE_IMG_URL } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";


const UserAvatarNormal = ({ user }) => {
    return (
        <div className="d-flex g-3  p-2 ">
            {user?.deleted_at == null && (
                <>

                    <Avatar className="rounded-2" title="asdsad" size={"50"} src={user?.profile_normal?.normal_profile_meta?.profile_image ? BASE_IMG_URL + user?.profile_normal?.normal_profile_meta?.profile_image : CatImg} />
                    <div className="ms-2 align-self-center">
                        <h6 className="text-gray-800 mb-0">{user?.profile_normal?.user_name}</h6>
                        <p className="text-gray-800 mb-0">{user?.email}</p>
                    </div>
                </>
            )}
            {user?.deleted_at && (
                <p className="text-danger">account</p>
            )}




        </div>
    )
}

export default UserAvatarNormal