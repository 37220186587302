import React, { useEffect } from 'react'
import Slider from "react-slick";
import Slide1 from '../../assets/images/home/slider-1.png';
import Slide2 from '../../assets/images/home/slider-2.png';
import Slide3 from '../../assets/images/home/slider-3.png';
import Slide4 from '../../assets/images/home/slider-4.png';
import Slide5 from '../../assets/images/home/slider-5.png';
import Slide6 from '../../assets/images/home/slider-6.png';
import Slide7 from '../../assets/images/home/slider-7.png';
import Slide8 from '../../assets/images/home/slider-8.png';

const Explore = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
      
    
    ]
   
  };

  return (
    <div className='explore-section section-pading  bg-primary'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12' data-aos="fade-down" >
            <h1 className='text-white text-center h1-regular'>Explore Heroself</h1>
            <hr className='under-line-custom-hr mx-auto bg-secondary' />
            <div className="slider-container">
              <Slider {...settings}>
                <div className="slide-custom">
                  <img src={Slide1} alt="Slide1" />
                </div>
                <div className="slide-custom">
                  <img src={Slide2} alt="Slide2" />
                </div>
                <div className="slide-custom">
                  <img src={Slide3} alt="Slide3" />
                </div>
                <div className="slide-custom">
                  <img src={Slide4} alt="Slide4" />
                </div>
                <div className="slide-custom">
                  <img src={Slide5} alt="Slide5" />
                </div>
                <div className="slide-custom">
                  <img src={Slide6} alt="Slide6" />
                </div>
                <div className="slide-custom">
                  <img src={Slide7} alt="Slide7" />
                </div>
                <div className="slide-custom">
                  <img src={Slide8} alt="Slide8" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Explore
