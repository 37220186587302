import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ListGroup, ListGroupItem, Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { apiPost } from '../Util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../Util/EndPoint';
import CatImg from "../assets/images/user.jpg";
import CustomTooltip from '../components/global/CustomTooltip';
import MediaGallery from '../components/global/MediaGallery';
import PostComment from '../components/global/PostComment';
import PostLikes from '../components/global/PostLikes';

const PostDetailsNormal = () => {
  const [postId, setPostId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [post, setPost] = useState({});
  const [loader, setLoader] = useState(false);


  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const postIds = urlParams.get('post_id');
    const userIds = urlParams.get('user_id');
    getPost(postIds, userIds);
    setUserId(userIds)
    setPostId(postIds)
  }, []);

  const getPost = (postIds, userIds) => {
    let body = {
      post_id: postIds,
      user_id: userIds
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.PostNormalProfile,
      body,
      (res) => {
        console.log(res.data, "res.data")
        setPost(res.data);
        setLoader(false);
      },
      (error) => {
        console.log(error, ' error');
        setLoader(false);
      },
    );
  };

  return (
    <>


      <div className="container   user-profile-nav-tabs">
        <h3 className='text-primary'>Normal Profile</h3>

        <Tabs
          defaultActiveKey="post-info"
          className="mb-3"
        >
          <Tab eventKey="post-info" title="Post info">
            <div className='row'>
              <div className='col-md-6'>
                <h3 className='user-info-heading bg-primary mb-0'>Post info</h3>
                <Table striped bordered hover className='user-detail-info-table border-primary'>
                  <tbody>
                    <tr>
                      <th>Created</th>
                      <td>{moment(post?.created_at).format("YYYY-MM-DD hh:mm:ss a")}</td>
                    </tr>

                    <tr>
                      <th>Group post</th>
                      <td> {post?.group_id ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Shared post</th>
                      <td> {post?.parent_id != 0 ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Background color</th>
                      <td> {post?.background_color ? post?.background_color : "-"}</td>
                    </tr>
                    <tr>
                      <th>Activity</th>
                      <td> {post?.post_activity}</td>
                    </tr>
                    <tr>
                      <th>For</th>
                      <td> {post?.post_for}</td>
                    </tr>
                    <tr>
                      <th>Like count</th>
                      <td>{post?.post_like_count ? post?.post_like_count?.post_like_count : 0}</td>
                    </tr>

                    <tr>
                      <th>Comments count</th>
                      <td>{post?.post_comments_count ? post?.post_comments_count?.post_comments_count : 0}</td>
                    </tr>

                    <tr>
                      <th>Share count</th>
                      <td>{post?.post_share_count ? post?.post_share_count?.post_share_count : 0}</td>
                    </tr>
                    <tr>
                      <th>post liked</th>
                      <td>{post?.is_post_like ? "Yes" : "No"}</td>
                    </tr>

                    <tr>
                      <th>Text</th>
                      <td>                      <CustomTooltip descriptionPro={post?.post_text} />
                      </td>
                    </tr>




                  </tbody>
                </Table>

              </div>
              <div className='col-md-6'>
                <div className='post-box-body-media border border-primary'>
                  <h3 className='user-info-heading bg-primary mb-0'>Post media</h3>
                  {post?.post_media?.length === 0 && (
                    <h6 className='text-center mt-2'>media not found</h6>
                  )}

                  {post?.post_media?.length > 0 && (
                    <MediaGallery selectedItemPro={post.post_media} />
                  )}

                </div>

                <div className='mt-3'>
                  <h3 className='user-info-heading bg-primary mb-0'>Tags users</h3>
                  <div className='border border-primary'>
                    <ListGroup>
                      {post?.tag_friend?.length > 0 &&
                        post.tag_friend.map((item, index) => (
                          <ListGroupItem key={index} >
                            <div className='post-box-header d-flex gap-2 p-1'>
                              <img src={item?.profile_normal?.normal_profile_meta?.profile_image ? BASE_IMG_URL + item?.profile_normal?.normal_profile_meta?.profile_image : CatImg} alt="profile" className='avatar-image border border-primary' />
                              <div className='post-box-header-text mb-0'>
                                <h6 className='mb-0'>{item?.user?.profile_normal?.user_name ? item?.user?.profile_normal?.user_name : "-"}</h6>
                                <p className='font-12 mb-0'>{item?.user?.email}</p>
                              </div>
                            </div>
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                    {post?.tag_friend?.length === 0 && (
                      <h6 className='text-center mt-2'>tags users not found</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="comments" title="Comments">
            {postId > 0 && (
              <PostComment
                commentTypePro="normal_profile_comment_post"
                profileTypePro="normal_profile"
                postIdPro={postId}
                userIdPro={userId}
              />
            )}

          </Tab>

          <Tab eventKey="likes" title="Likes">
            {postId > 0 && (
              <PostLikes
                postLikeTypePro="normal_post_like"
                profileTypePro="normal_profile"
                postIdPro={postId}
                userIdPro={userId}
              />
            )}
          </Tab>
        </Tabs>





      </div >
    </>
  );
};

export default PostDetailsNormal;
