import React from "react";
import { Container } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Slider from "react-slick";
import { BASE_IMG_URL } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";



const SliderWithTitle = ({ sliderPro }) => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container className="mt-3 mb-5 ">
        <Slider className="slider-with-title" {...settings}>
          {Array.isArray(sliderPro) && sliderPro?.length > 0 &&
            sliderPro?.map((item, index) => (
              <div key={index} className="">
                <Card >
                  <Card.Img variant="top" src={item?.path ? BASE_IMG_URL + item?.path : CatImg} />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>

                  </Card.Body>
                </Card>
              </div>
            ))}
        </Slider>
      </Container>
    </>
  );
}

export default SliderWithTitle

