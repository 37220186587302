import { Button, Card, Tab, Tabs } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useRecoilState } from "recoil";
import { apiPost } from "../../Util/ApiRequest";
import { isLoaderState } from "../../Util/RecoilStore";
import { BASE_IMG_URL, ENDPOINTS } from '../../Util/EndPoint';
import { ProfileTypeEnum, removeUnderscore } from '../../Util/Helper';
import CatImg from "../../assets/images/user.jpg";
import { Player } from 'video-react';



const UserGallery = ({ userId, profileType }) => {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);

  const [fileType, setFileType] = useState('image');



  const [items, setItems] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);



  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getPaginatedUsers(url, fileType);
  }, [fileType]);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getPaginatedUsers(url, fileType);
  };


  const handleTypeChange = (type) => {
    setFileType(type)
    getPaginatedUsers(currentUrl, type)
  }


  const getPaginatedUsers = (url, type) => {
    setIsLoaderInfo(true);
    apiPost(
      ENDPOINTS.GalleryPaginated + url,
      {
        profile_type: profileType,
        user_id: userId,
        file_type: type
      },
      (res) => {
        setItems(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
        setIsLoaderInfo(false);
      },
      (error) => {
        console.log(error);
        setIsLoaderInfo(false);
      }
    );
  };



  return (
    <>

      <div className='mt-4  user-profile-nav-tabs'>
        <Tabs
          defaultActiveKey="image"
          className="mb-3"
          onSelect={handleTypeChange}
        >

          <Tab eventKey="image" title="Images">
            {userId > 0 && (

              <div className='row'>
                {fileType == "image" && (
                  items?.length > 0 &&
                  items.map((item, index) => (
                    <Card key={index} className='col-md-4 col-lg-3 col-sm-6'>
                      <Card.Img className='user-gallery' variant="top" src={item?.media ? BASE_IMG_URL + item?.media : CatImg} />
                      <Card.Body>
                        <Card.Title>{removeUnderscore(item?.media_for)}</Card.Title>
                      </Card.Body>
                    </Card>))

                )}
              </div>

            )}

            <ReactPaginate
              autoWidth
              className="pagination-custom mt-4"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={totalPage}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          </Tab>
          <Tab eventKey="video" title="Videos">
            {userId > 0 && (

              <div className='row'>
                {fileType == "video" && (
                  items?.length > 0 &&
                  items.map((item, index) => (
                    <Card key={index} className='col-md-4 col-lg-3 col-sm-6'>
                      {item?.media && item?.media_type == "video" &&(
                        <Player>
                          <source src={BASE_IMG_URL + item?.media} />
                        </Player>
                      )}
                      <span className='font-10 text-danger'>There is an error in video</span>

                      <Card.Body>
                        <Card.Title>{removeUnderscore(item?.media_for)}</Card.Title>
                      </Card.Body>
                    </Card>
                  ))

                )}
              </div>

            )}

            <ReactPaginate
              autoWidth
              className="pagination-custom mt-4"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={totalPage}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          </Tab>

        </Tabs>
      </div>
    </>
  )
}

export default UserGallery