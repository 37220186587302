import moment from "moment";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS } from "../Util/EndPoint";
import { ProfileTypeEnum } from "../Util/Helper";
import CustomTooltip from "../components/global/CustomTooltip";
import TableComponent from "../components/global/TableComponent";
import UserAvatarNormal from "../components/global/UserAvatarNormal";

const PostNormal = () => {
    const navigate = useNavigate();


    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: false,
            width: '60px'
        },
        {
            name: 'User',
            selector: (row) => <UserAvatarNormal user={row?.user} />,
            sortable: false,
            wrap: true,
            maxWidth: '400px',
            minWidth: '250px'
        },
        {
            name: 'Post text',
            selector: (row) => <CustomTooltip descriptionPro={row?.post_text} />,
            sortable: false,
            wrap: true,
            maxWidth: '250px',
            minWidth: '200px'
        },
        {
            name: 'Activity',
            selector: (row) => row?.post_activity ? row?.post_activity : '-',
            sortable: false,
        },
        {
            name: 'Post For',
            selector: (row) => row?.post_for ? row?.post_for : '-',
            sortable: false,
        },
        {
            name: 'Check In',
            selector: (row) => row?.check_in ? row?.check_in : '-',
            sortable: false,
        },
        {
            name: 'Created At',
            selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
            sortable: false,
        },
        {
            name: 'Comments Count',
            selector: (row) => row?.post_comments_count ? row?.post_comments_count?.post_comments_count : 0,
            sortable: false,
        },
        {
            name: 'Like Count',
            selector: (row) => row?.post_like_count ? row?.post_like_count?.post_like_count : 0,
            sortable: false,
        },
        {
            name: 'Share Count',
            selector: (row) => row?.post_share_count ? row?.post_share_count?.post_share_count : 0,
            sortable: false,
        },
        {
            name: 'Detail',
            selector: (row) => (
                <button className="btn btn-primary" onClick={() => {
                    navigate(`/admin/post-detail-normal?user_id=${row?.user_id}&post_id=${row?.id}`);
                }}>
                    detail
                </button>
            ),
            sortable: false,
        },
    ];


    return (
        <>
            <TableComponent
                title="Posts Normal"
                endPoint={ENDPOINTS.PaginatedPost}
                headers={columns}
                searchObject={{ profile_type: ProfileTypeEnum.NormalProfile }}

            />
        </>
    )
}
export default PostNormal;
