import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import React from "react";
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Normal Reported post',
        },
    },
};
const ReportedPostsNormal = ({ ReportedPostsNormalPro }) => {
    let labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const data = {
        labels,
        datasets: [
            {
                label: 'Reported post',
                data: labels.map((item, index) => {
                    let single = ReportedPostsNormalPro.find(e => e.month === item)
                    return single ? single?.user_count : 0
                }),
                backgroundColor: '#a1702c',
            },

        ],
    };

    return (
        <div>
            <Bar options={options} data={data} />
        </div>
    )
}

export default ReportedPostsNormal