import React, { useEffect } from 'react';
import embracescreen from '../../assets/images/home/embrace-screen.png';
import diversescreen from '../../assets/images/home/diverse-screen.png';
import callcenter from '../../assets/images/home/call-center.png';
import volume from '../../assets/images/home/volume.png';
import multimedia from '../../assets/images/home/multimedia.png';
import iconsearch from '../../assets/images/home/icon-search.png';

const Embrace = () => {
    return (
        <>

            <div className='embrace-section section-pading bg-primary'>
                <div className='container'>
                    <div className='row align-items-center embrace-section-row'>
                        <div className='col-lg-6 col-md-12 text-center mt-4'>
                            <div data-aos="fade-down">
                                <img src={embracescreen} alt="Embrace Screen" />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div data-aos="fade-up">
                                <h1 className='text-white h1-regular'>Embrace Your Duality: Two Faces, One Digital Identity</h1>
                                <hr className='under-line-custom-hr bg-secondary' />
                                <p className='text-white text-wrap'>Heroself is your digital playground where you can seamlessly embody
                                    both your virtuous side and your mischievous alter ego. It’s a platform designed to celebrate
                                    the complexities of your identity, allowing you to share moments, connect with others, and
                                    express yourself fully.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='diverse-section section-pading bg-primary'>
                <div className='container'>
                    <div className='row align-items-center diverse-row '>
                        <div className='col-lg-6 col-md-12'>
                            <div data-aos="fade-down">
                                <h1 className='text-white h1-regular'>Diverse Features for Every <br /> Persona</h1>
                                <hr className='under-line-custom-hr bg-secondary' />
                                <p className='text-white text-wrap'>Heroself offers a plethora of features designed to enrich your dual personal
                                    experience. From seamlessly navigating between your primary self and alter
                                    ego to engaging in tailored News Feed segments, Heroself empowers you to
                                    express the complexities of your identity with ease.</p>
                                <div className='row mt-3'>
                                    <div className='col-md-6 mt-3 mb-3'>
                                        <div className='box'>
                                            <img src={iconsearch} alt='iconsearch' />
                                            <h3 className='text-white mt-3'>Dual Profiles</h3>
                                            <p className='text-white'>Seamlessly switch between your primary
                                                self and alter ego.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-3 mb-3'>
                                        <div className='box'>
                                            <img src={volume} alt='volume' />
                                            <h3 className='text-white mt-3'>News Feed Segmented</h3>
                                            <p className='text-white'>Engage in distinct realms
                                                tailored to each persona.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 mt-3 mb-3'>
                                        <div className='box'>
                                            <img src={multimedia} alt='multimedia' />
                                            <h3 className='text-white mt-3'>Rich Multimedia Sharing </h3>
                                            <p className='text-white'>Share photos, videos, check-ins,
                                                and more with vivid detail.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-3 mb-3'>
                                        <div className='box'>
                                            <img src={callcenter} alt='callcenter' />
                                            <h3 className='text-white mt-3'>Community Building</h3>
                                            <p className='text-white'>Connect with like-minded individuals
                                                through groups, events, chats,
                                                and notifications.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 text-center'>
                            <div data-aos="fade-up">
                                <img src={diversescreen} alt="Diverse Screen" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Embrace
