import React from "react";

const CustomTags = ({ itemsPro }) => {
  return (
    <>
      {itemsPro?.length > 0 &&
        itemsPro.map((item, index) => (
          <span className="badge bg-primary p-2 me-1" key={index}>
            {item}
          </span>
        ))}

    </>
  );
};

export default CustomTags;
