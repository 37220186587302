import React from "react";
import { ENDPOINTS } from "../Util/EndPoint";
import { ProfileTypeEnum } from "../Util/Helper";
import TableComponent from "../components/global/TableComponent";
import UserAvatarNormal from "../components/global/UserAvatarNormal";
import UserAlterEgoProfile from "../components/global/UserAlterEgoProfile";
const BlockUserAlterEgo = () => {

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: false,
            width: '60px'
        },
        {
            name: 'Block By',
            selector: (row) => <UserAlterEgoProfile user={row?.user_block_by} />,
            sortable: false,
            wrap: true,
            maxWidth: '400px',
            minWidth: '250px'
        },

        {
            name: 'Block To',
            selector: (row) => <UserAlterEgoProfile user={row?.user_block_to} />,
            sortable: false,
            wrap: true,
            maxWidth: '400px',
            minWidth: '250px'
        },

    ];


    return (
        <>

            <TableComponent
                title="User Block AlterEgo"
                endPoint={ENDPOINTS.PaginatedBlockUser}
                headers={columns}
                searchObject={{ profile_type: ProfileTypeEnum.AlterEgoProfile }}

            />
        </>
    )
}
export default BlockUserAlterEgo;
