import React from 'react'

const Copyright = () => {
    return (
        <div className='copy-right-sect bg-secondary pt-3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='text-primary text-primary text-center '>© 2024, <strong>HEROSELF</strong>, All Rights Reserved, Developed By 
                        <a href="https://gologonow.com/" target='_blank' className='a-decuration-text text-primary'> <strong>GoLogoNow</strong></a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Copyright
