import { atom } from 'recoil';

export const isLoaderState = atom({
    key: 'isLoaderState',
    default: false
});

export const snakeBarState = atom({
    key: 'snakeBarState',
    default: {
        snackStatus: false,
        snackColor: "bg-primary",
        snackMsg: ""
    },
});
