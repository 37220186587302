import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { apiPost } from "../Util/ApiRequest";
import { ENDPOINTS } from "../Util/EndPoint";
import Loader from "../components/global/Loader";
import UserAlterEgoProfile from "../components/global/UserAlterEgoProfile";


const ReportedPostAlterEgo = () => {
  const [deleteModal, setDeleteModal] = useState(false);

  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);
  const [mediaModal, setMediaModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getAllReportedPostsWithPagination(url);
  }, []);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllReportedPostsWithPagination(url);
  };

  const getAllReportedPostsWithPagination = (url) => {
    let body = {
      profile_type: "alter_ego_profile",
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.ReportedPost + url,
      body,
      (res) => {
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  const deleteReport = () => {
    let body = {
      post_id: selectedItem.post_id,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.DeleteReportedPost,
      body,
      (res) => {
        setLoader(false);
        setDeleteModal(false);
        getAllReportedPostsWithPagination(currentUrl)
      },
      (error) => {
        setDeleteModal(false);

        setLoader(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto p-2">Reported posts</h5>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Reported By</th>
                <th>Reported id</th>
                <th>Post id</th>
                <th>Issue</th>
                <th>Created</th>
                <th>Delete</th>
                <th>Detail</th>

              </tr>
            </thead>
            <tbody>
              {items?.length > 0 &&
                items.map((item, index) => (
                  <tr key={index}>
                    <td className="position-relative">
                      <UserAlterEgoProfile itemPro={item?.user} />
                    </td>
                    <td className="position-relative">
                      <p className="mb-0 ">{item?.id}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0 ">{item?.post_id}</p>
                    </td>

                    <td className="position-relative">
                      <p className="mb-0 ">{item?.text}</p>
                    </td>

                    <td>{moment(item?.created_at).format("YYYY-MM-DD hh:mm:ss a")}</td>
                    <td>
                      <button className="btn btn-danger" onClick={() => {
                        setSelectedItem(item)
                        setDeleteModal(true)
                      }}>Delete</button>
                    </td>

                    <td className="position-relative">
                      <button className="btn btn-primary" onClick={() => {
                        window.location.href = `/admin/post-detail-alter-ego?user_id=${item?.reported_posts?.user_id}&post_id=${item.post_id}`;
                      }}>detail</button>
                    </td>

                  </tr>
                ))}
            </tbody>
          </Table>
          {items?.length === 0 && (
            <h6 className='text-center mt-2'>reported post not found</h6>
          )}
        </div>
        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
      <Modal size="sm" show={deleteModal}>
        <Modal.Header className='bg-primary text-white'>
          <Modal.Title>Delete post</Modal.Title>
        </Modal.Header>
        <Modal.Body> you are you want to delete post!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            No
          </Button>
          <Button variant="danger" onClick={deleteReport}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportedPostAlterEgo;
