import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ENDPOINTS } from '../Util/EndPoint';
// cover-img
import { useNavigate } from 'react-router-dom';
import { ProfileTypeEnum } from '../Util/Helper';
import "../assets/pages-css/user-detail.css";
import CustomTooltip from '../components/global/CustomTooltip';
import TableComponent from '../components/global/TableComponent';
import UserAlterEgoProfile from '../components/global/UserAlterEgoProfile';


const GroupDetailAlterEgo = () => {
  const [groupId, setGroupId] = useState(0);
  const [loader, setLoader] = useState(false);


  const navigate = useNavigate();
  const postColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },
    {
      name: 'User',
      selector: (row) => <UserAlterEgoProfile user={row?.user} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },
    {
      name: 'Post text',
      selector: (row) => <CustomTooltip descriptionPro={row?.post_text} />,
      sortable: false,
      wrap: true,
      maxWidth: '250px',
      minWidth: '200px'
    },
    {
      name: 'Activity',
      selector: (row) => row?.post_activity ? row?.post_activity : '-',
      sortable: false,
    },
    {
      name: 'Post For',
      selector: (row) => row?.post_for ? row?.post_for : '-',
      sortable: false,
    },
    {
      name: 'Check In',
      selector: (row) => row?.check_in ? row?.check_in : '-',
      sortable: false,
    },
    {
      name: 'Created At',
      selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      sortable: false,
    },
    {
      name: 'Comments Count',
      selector: (row) => row?.post_comments_count ? row?.post_comments_count?.post_comments_count : 0,
      sortable: false,
    },
    {
      name: 'Like Count',
      selector: (row) => row?.post_like_count ? row?.post_like_count?.post_like_count : 0,
      sortable: false,
    },
    {
      name: 'Share Count',
      selector: (row) => row?.post_share_count ? row?.post_share_count?.post_share_count : 0,
      sortable: false,
    },
    {
      name: 'Detail',
      selector: (row) => (
        <button className="btn btn-primary" onClick={() => {
          navigate(`/admin/post-detail-normal?user_id=${row?.user_id}&post_id=${row?.id}`);
        }}>
          detail
        </button>
      ),
      sortable: false,
    },
  ];


  const groupMembersColumn = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },
    {
      name: 'User',
      selector: (row) => <UserAlterEgoProfile user={row?.user} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },
    {
      name: 'Role',
      selector: (row) => row?.role,
      sortable: false,
    },


    {
      name: 'Created At',
      selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      sortable: false,
    },

  ];


  useEffect(() => {
    let groupIds = /[^/]*$/.exec(window.location.href)[0];
    setGroupId(groupIds);
  }, [groupId]);



  return (
    <>
      <div className='main-profile-box'>

        <div className=' user-profile-main'>
          <h3 className='text-primary'>Normal Profile Group </h3>

          <div className='mt-4  user-profile-nav-tabs'>
            <Tabs
              defaultActiveKey="posts"
              className="mb-3"
            >


              <Tab eventKey="posts" title="Group Posts">
                {groupId > 0 && (
                  <TableComponent
                    title="Group Posts"
                    endPoint={ENDPOINTS.PaginatedPost}
                    headers={postColumns}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      group_id: groupId,
                    }}

                  />
                )}
              </Tab>

              <Tab eventKey="group-members" title="Group Members">
                {groupId > 0 && (
                  <TableComponent
                    title="Group Members"
                    endPoint={ENDPOINTS.GroupMembersPaginated}
                    headers={groupMembersColumn}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      group_id: groupId,
                      user_type: 'all'
                    }}

                  />
                )}
              </Tab>

              <Tab eventKey="group-admins" title="Group admins">
                {groupId > 0 && (
                  <TableComponent
                    title="Group admins"
                    endPoint={ENDPOINTS.GroupMembersPaginated}
                    headers={groupMembersColumn}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      group_id: groupId,
                      user_type: 'admin'
                    }}

                  />
                )}
              </Tab>

            </Tabs>
          </div>
        </div>
      </div >

    </>
  );
};

export default GroupDetailAlterEgo;
