import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CgDanger } from 'react-icons/cg';
import {
  FaHome,
  FaRegCommentAlt,
  FaRegNewspaper,
  FaUserFriends
} from 'react-icons/fa';
import { IoLogOut } from 'react-icons/io5';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { ProfileTypeTextEnum } from '../../Util/Helper';
import BadgeCustom from '../global/BadgeCustom';
import './Sidebar.css';


const SidebarComponent = () => {

  const [burgerShow, setBurgerShow] = useState(false);
  const [urlParam, setUrlParam] = useState(window.location.pathname.split('/').pop(),);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <>
      <Sidebar
        className="sidebar-dashboard"
        backgroundColor="#26282b"
        collapsed={burgerShow}
      >


        <Menu iconShape="square">
          <MenuItem
            active={urlParam === "dashboard" ? true : false}
            onClick={() => setUrlParam("dashboard")}
            icon={<FaHome />}
            className="sidebar-menu-design  sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/dashboard"}>
              Dashboard
            </Link>
          </MenuItem>
          <MenuItem
            active={urlParam === "users" ? true : false}
            onClick={() => setUrlParam("users")}
            icon={<FaUserFriends />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/users"}>
              Users
            </Link>
          </MenuItem>

          <hr className='custom-hr' />

          <MenuItem
            active={urlParam === "posts-alter-ego" ? true : false}
            onClick={() => setUrlParam("posts-alter-ego")}
            icon={<FaRegNewspaper />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/posts-alter-ego"}>
              Posts
              <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.AlterEgoProfile}
              />
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "reported-post-alter-ego" ? true : false}
            onClick={() => setUrlParam("reported-post-alter-ego")}
            icon={<FaRegCommentAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/reported-post-alter-ego"}>
              Reported post
              <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.AlterEgoProfile}
              />
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "block-user-alter-ego" ? true : false}
            onClick={() => setUrlParam("block-user-alter-ego")}
            icon={<FaRegCommentAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/block-user-alter-ego"}>
              Block users   <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.AlterEgoProfile}
              />
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "groups-alter-ego" ? true : false}
            onClick={() => setUrlParam("groups-alter-ego")}
            icon={<FaRegCommentAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/groups-alter-ego"}>
              Group <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.AlterEgoProfile}
              />
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "event-alter-ego" ? true : false}
            onClick={() => setUrlParam("event-alter-ego")}
            icon={<FaRegCommentAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/event-alter-ego"}>
              Event <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.AlterEgoProfile}
              />
            </Link>
          </MenuItem>

          <hr className='custom-hr' />




          <MenuItem
            active={urlParam === "posts-normal" ? true : false}
            onClick={() => setUrlParam("posts-normal")}
            icon={<FaRegNewspaper />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/posts-normal"}>
              Posts
              <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.NormalProfile}
              />
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "reported-post-normal" ? true : false}
            onClick={() => setUrlParam("reported-post-normal")}
            icon={<FaRegNewspaper />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/reported-post-normal"}>
              Reported post
              <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.NormalProfile}
              />
            </Link>
          </MenuItem>


          <MenuItem
            active={urlParam === "block-user-normal" ? true : false}
            onClick={() => setUrlParam("block-user-normal")}
            icon={<FaRegCommentAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/block-user-normal"}>
              Block users   <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.NormalProfile}
              />
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "groups-normal" ? true : false}
            onClick={() => setUrlParam("groups-normal")}
            icon={<FaRegCommentAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/groups-normal"}>
              Group <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.NormalProfile}
              />
            </Link>
          </MenuItem>

          <MenuItem
            active={urlParam === "event-normal" ? true : false}
            onClick={() => setUrlParam("event-normal")}
            icon={<FaRegCommentAlt />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            <Link className="nav-link" to={"/admin/event-normal"}>
              Event <BadgeCustom
                bgColor={'bg-primary'}
                text={ProfileTypeTextEnum.NormalProfile}
              />
            </Link>
          </MenuItem>



          <hr className='custom-hr' />




          <MenuItem
            onClick={() => setModalShow(true)}
            icon={<IoLogOut />}
            className="sidebar-menu-design sidebar-menu-design-custom"
          >
            Logout
          </MenuItem>

        </Menu>

      </Sidebar>

      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div className="d-flex gap-2"><h4 className="mb-0 text-danger"><CgDanger /> </h4> <h6 className="my-auto">
          Are you sure you want to logout ?
        </h6></div> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => logOut()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal >

    </>
  );
};

export default SidebarComponent;