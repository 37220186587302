import React from "react";
import Avatar from "react-avatar";
const ImageAvatar = ({ src }) => {
  return (
    <>
      <Avatar className="rounded-2" title="asdsad" size={"50"} src={src} />

    </>
  );
};

export default ImageAvatar;
