import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaArrowUp, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { apiPost } from '../Util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../Util/EndPoint';
import CoverImg from "../assets/images/cover-img.png";
import CatImg from "../assets/images/user.jpg";
// cover-img
import "../assets/pages-css/user-detail.css";
import CustomTags from '../components/global/CustomTags';
import CustomTooltip from '../components/global/CustomTooltip';
import FriendList from '../components/global/FriendList';
import MyPosts from '../components/global/MyPosts';
import SliderWithTitle from '../components/global/SliderWithTitle';
import TableComponent from '../components/global/TableComponent';
import UserGallery from '../components/global/UserGallery';
import { ProfileTypeEnum } from '../Util/Helper';
import UserAlterEgoProfile from '../components/global/UserAlterEgoProfile';
import ImageAvatar from '../components/global/ImageAvatar';

const UsersDetailAlterEgoProfile = () => {
  const [userId, setUserId] = useState(0);
  const [userObj, setUserObj] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let userId = /[^/]*$/.exec(window.location.href)[0];
    setUserId(userId);
    getUserInfo(userId);
  }, []);

  const getUserInfo = (userId) => {
    let body = {
      user_id: userId,
      profile_type: "alter_ego_profile"
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.UserDetailsNormalProfile,
      body,
      (res) => {
        setUserObj(res.data);
        setLoader(false);
      },
      (error) => {
        console.log(error, ' error');
        setLoader(false);
      },
    );
  };


  const blockList = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },
    {
      name: 'Block By',
      selector: (row) => <UserAlterEgoProfile user={row?.user_block_by} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },
    {
      name: 'Block To',
      selector: (row) => <UserAlterEgoProfile user={row?.user_block_to} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },
    {
      name: 'Created At',
      selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      sortable: false,
    },

  ];

  const createdGroups = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },

    {
      name: 'Name',
      selector: (row) => row?.group_name,
      sortable: false,
    },
    {
      name: 'Image',
      selector: (row) => <ImageAvatar src={row?.cover_image ? BASE_IMG_URL + row?.cover_image : CatImg} />,
      sortable: false,
    },
    {
      name: 'Public',
      selector: (row) => row?.privacy == "public" ? "yes" : "no",
      sortable: false,
    },
    {
      name: 'Members',
      selector: (row) => row?.members?.length,
      sortable: false,
    },
    {
      name: 'is join',
      selector: (row) => row?.is_join ? "yes" : "no",
      sortable: false,
    },


    {
      name: 'Created At',
      selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      sortable: false,
    },

  ];

  const eventsColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },

    {
      name: 'Creator',
      selector: (row) => <UserAlterEgoProfile user={row?.creator} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },

    {
      name: 'Image',
      selector: (row) => <ImageAvatar src={row?.photos[0] ? BASE_IMG_URL + row?.photos[0].filename : CatImg} />,
      sortable: false,
    },
    {
      name: 'Name',
      selector: (row) => row?.event_name,
      sortable: false,
    },
    {
      name: 'Description',
      selector: (row) => <CustomTooltip descriptionPro={row?.description} />,
      sortable: false,
    },

    {
      name: 'Event Venue Name',
      selector: (row) => <CustomTooltip descriptionPro={row?.event_venue_name} />,
      sortable: false,
    },

    {
      name: 'Image',
      selector: (row) => <ImageAvatar src={row?.media ? BASE_IMG_URL + row?.media : CatImg} />,
      sortable: false,
    },

    {
      name: 'Event Date',
      selector: (row) => moment(row?.event_date).format("YYYY-MM-DD hh:mm:ss a"),
      sortable: false,
    },

  ];

  const commentsColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },
    {
      name: 'User',
      selector: (row) => <UserAlterEgoProfile user={row?.user} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },


    {
      name: 'Comments',
      selector: (row) => row?.body,
      sortable: false,
    },




  ];


  return (
    <>
      <div>
        <div className=' user-profile-main'>
          <h3 className='text-primary'>Alter Ego Profile</h3>

          <div className='user-profile'

            style={{ backgroundImage: `url(${userObj?.profile_meta?.cover_image ? BASE_IMG_URL + userObj?.profile_meta?.cover_image : CoverImg})` }}
          >
            <div className='user-image-box'>
              <img src={userObj?.profile_meta?.profile_image ? BASE_IMG_URL + userObj?.profile_meta?.profile_image : CatImg} alt="profile image" className='user-image' />
            </div>
            <div className='user-info-box'>
              <div className='user-info-box-personal'>
                <h3 className='text-white black-text-shadow'>{userObj?.profile_alter_ego?.user_name} <span className='font-icon-14 text-success'><GoVerified /></span></h3>
                <div className='info-icon'>
                  <p className='text-white black-text-shadow'><span className='font-icon-12'><FaEnvelope /></span>  {userObj?.email}</p>
                  <p className='text-white black-text-shadow'> <span className='font-icon-12'><FaPhoneAlt /></span> {userObj?.profile_alter_ego?.alter_ego_profile_meta?.phone_number}</p>
                </div>
              </div>
              <div className='user-info-app'>
                <div className='info-icon-app'>
                  <h5 className='text-white black-text-shadow'><span className='text-success font-icon-12'><FaArrowUp /></span> {userObj?.posts_count ? userObj.posts_count : 0}</h5>
                  <p className='text-white black-text-shadow'>Total posts</p>
                </div>

              </div>
            </div>

          </div>

          <div className='mt-4  user-profile-nav-tabs'>
            <Tabs
              defaultActiveKey="overview"
              className="mb-3"
            >
              <Tab eventKey="overview" title="Overview">
                <div className='mt-4'>
                  <Row>
                    <Col md="6">
                      <h4 className='user-info-heading bg-primary'>Profile alter ego</h4>
                      <Table striped bordered hover className='user-detail-info-table'>
                        <tbody>
                          <tr>
                            <th>Account created</th>
                            <td>{moment(userObj?.created_at).format("LL")}</td>
                          </tr>
                          <tr>
                            <th>User id</th>
                            <td>{userObj?.id}</td>
                          </tr>
                          <tr>
                            <th>Username</th>
                            <td>{userObj?.profile_alter_ego?.user_name}</td>
                          </tr>
                          <tr>
                            <th>Profile status</th>
                            <td>{userObj?.profile_alter_ego?.profile_status}</td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.name}</td>
                          </tr>

                          <tr>
                            <th>Phone number</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.phone_number}</td>
                          </tr>
                          <tr>
                            <th>Date of birth</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.date_of_birth}</td>
                          </tr>


                          <tr>
                            <th>Short bio</th>
                            <td> <CustomTooltip descriptionPro={userObj?.profile_alter_ego?.alter_ego_profile_meta?.short_bio} /></td>
                          </tr>
                          <tr>
                            <th>Life story</th>
                            <td><CustomTooltip descriptionPro={userObj?.profile_alter_ego?.alter_ego_profile_meta?.life_story} /></td>
                          </tr>


                          <tr>
                            <th>Relation status</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.relation_status}</td>
                          </tr>
                          <tr>
                            <th>Gender</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.gender}</td>
                          </tr>

                          <tr>
                            <th>University</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.university_education}</td>
                          </tr>


                          <tr>
                            <th>School</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.school_education}</td>
                          </tr>


                          <tr>
                            <th>Work experience</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.work_experience}</td>
                          </tr>

                          <tr>
                            <th>Friend request</th>
                            <td>{userObj?.profile_alter_ego?.friend_request}</td>
                          </tr>

                          <tr>
                            <th>Message send</th>
                            <td>{userObj?.profile_alter_ego?.message_send}</td>
                          </tr>
                          <tr>
                            <th>Love interests</th>
                            <td>  <CustomTags itemsPro={userObj?.profile_alter_ego?.alter_ego_profile_meta?.love_interests} /></td>
                          </tr>

                          <tr>
                            <th>Country</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.location?.country}</td>
                          </tr>
                          <tr>
                            <th>State</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.location?.state}</td>
                          </tr>
                          <tr>
                            <th>City</th>
                            <td>{userObj?.profile_alter_ego?.alter_ego_profile_meta?.location?.city}</td>
                          </tr>

                        </tbody>
                      </Table>
                    </Col>
                    <Col md="6">

                      {userObj?.profile_alter_ego?.alter_ego_profile_meta?.favorite_restaurant?.length > 0 && (
                        <div className='mb-4'>
                          <h3 className="text-primary">Favorite restaurant</h3>
                          <SliderWithTitle sliderPro={userObj?.profile_alter_ego?.alter_ego_profile_meta?.favorite_restaurant} />
                        </div>

                      )}

                      {userObj?.profile_alter_ego?.alter_ego_profile_meta?.villains_images?.length > 0 && (
                        <div className='mb-4'>
                          <h3 className="text-primary">villains</h3>
                          <SliderWithTitle sliderPro={userObj?.profile_alter_ego?.alter_ego_profile_meta?.villains_images} />
                        </div>
                      )}


                      {userObj?.profile_alter_ego?.alter_ego_profile_meta?.people_dumped_images?.length > 0 && (
                        <div className='mb-4'>
                          <h3 className="text-primary">People dumped</h3>
                          <SliderWithTitle sliderPro={userObj?.profile_alter_ego?.alter_ego_profile_meta?.people_dumped_images} />
                        </div>
                      )}

                      {userObj?.profile_alter_ego?.alter_ego_profile_meta?.favorite_place?.length > 0 && (
                        <div className='mb-4'>
                          <h3 className="text-primary">Favorite place</h3>
                          <SliderWithTitle sliderPro={userObj?.profile_alter_ego?.alter_ego_profile_meta?.favorite_place} />
                        </div>
                      )}
                    </Col>
                  </Row>


                </div>
              </Tab>
              <Tab eventKey="friends" title="Friends">
                {userId > 0 && (
                  <FriendList profileTypePro={ProfileTypeEnum.AlterEgoProfile} userIdPro={userId} />
                )}
              </Tab>
              <Tab eventKey="my-wishes" title="Posts">
                {userId > 0 && (
                  <MyPosts profileTypePro={ProfileTypeEnum.AlterEgoProfile} userIdPro={userId} />
                )}
              </Tab>
              <Tab eventKey="comments" title="Comments">
                {userId > 0 && (
                  <TableComponent
                    title="Comments"
                    endPoint={ENDPOINTS.CommentsPaginated}
                    headers={commentsColumns}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      user_id: userId

                    }}

                  />
                )}
              </Tab>
              <Tab eventKey="photos" title="Photos">
                {userId > 0 && (
                  < UserGallery
                    userId={userId}
                    profileType={ProfileTypeEnum.AlterEgoProfile}
                  />
                )}
              </Tab>
              <Tab eventKey="events" title="Events">
                {userId > 0 && (
                  <TableComponent
                    title="Events"
                    endPoint={ENDPOINTS.EventsPaginated}
                    headers={eventsColumns}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      user_id: userId

                    }}

                  />
                )}
              </Tab>
              <Tab eventKey="created-groups" title="Created Groups">
                {userId > 0 && (
                  <TableComponent
                    title="Created Groups"
                    endPoint={ENDPOINTS.GroupsPaginated}
                    headers={createdGroups}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      user_id: userId,
                      created_by: userId,

                    }}

                  />
                )}
              </Tab>
              <Tab eventKey="joined-groups" title="Joined Groups">
                {userId > 0 && (
                  <TableComponent
                    title="Joined Groups"
                    endPoint={ENDPOINTS.GroupsPaginated}
                    headers={createdGroups}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      user_id: userId,
                      created_by: userId,
                      is_join: 1


                    }}

                  />
                )}
              </Tab>
              <Tab eventKey="block-list" title="Block List">
                {userId > 0 && (
                  <TableComponent
                    title="Block Users"
                    endPoint={ENDPOINTS.PaginatedBlockUser}
                    headers={blockList}
                    searchObject={{
                      profile_type: ProfileTypeEnum.AlterEgoProfile,
                      user_id: userId

                    }}

                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div >

    </>
  );
};

export default UsersDetailAlterEgoProfile;
