import React,{useEffect} from 'react'
import "../assets/pages-css/home.css";
import Welcomes from '../components/home/welcomes';
import Embrace from '../components/home/embrace';
import Getstartedtoday from '../components/home/getstartedtoday';
import Explore from '../components/home/explore';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Userlove from '../components/home/userlove';
import Joindual from '../components/home/joindual';
import Contactus from '../components/home/contactus';
import Footer from '../components/home/footer';
import Copyright from '../components/home/copyright';
import LogoCom from '../components/home/LogoCom';


const Home = () => {

  return (
    <div >
      <Welcomes /> 
      <Embrace/>
      <Getstartedtoday/>
      <Explore/>
      <Userlove/>
      <Joindual/>
      <Contactus /> 
      <Footer/>
      <Copyright />
    </div>
  )
}

export default Home
