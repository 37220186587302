import React from 'react';
import { BASE_IMG_URL } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";

const UserNormalProfile = ({ itemPro }) => {
  return (

    <div className="d-flex align-items-center">
      <div className="symbol symbol-45px me-2">
        <img src={itemPro?.profile_normal?.normal_profile_meta?.profile_image ? BASE_IMG_URL + itemPro?.profile_normal?.normal_profile_meta?.profile_image : CatImg} alt="dsdasda" /></div><div className="d-flex justify-content-start flex-column">
        <p className="text-dark fw-bold text-hover-primary fs-6 mb-0">{itemPro?.profile_normal?.user_name}</p>
        <span className="text-muted fw-semibold text-muted d-block fs-7">{itemPro?.profile_normal?.normal_profile_meta?.name}</span>
      </div>
    </div>

  )
}

export default UserNormalProfile