import React, { useEffect } from 'react';
import pin from '../../assets/images/home/pin.png';
import phone from '../../assets/images/home/phone-call.png';
import email from '../../assets/images/home/email.png';
import AOS from 'aos';

const Contactus = () => {

    return (
        <div className='contact-us-sect bg-primary'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='text-white h1-regular text-center pb-3'>Our Contact Us</h1>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-6 ps-5 pe-5 pb-5'>
                        <div data-aos="fade-up">
                            <h2 className='text-white'>Get In Touch With Us</h2>
                            <form className='contact-us-form pt-5'>
                                <div className='row'>
                                    <div className='col-md-6 mb-4'>
                                        <input type='text' className="form-control" name='firstname' placeholder='First Name' />
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <input type='text' className="form-control" name='lastname' placeholder='last Name' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 mb-4'>
                                        <input type='email' className="form-control" name='email' placeholder='Enter your email' />
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <input type='text' className="form-control" name='subject' placeholder='Subject' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <textarea name="message" placeholder='Message' className="form-control" rows="3" />
                                        <button type="submit" className="send-btn mt-4">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-6 cont-col ps-3 pe-3'>
                        <div data-aos="fade-down">
                            <h2 className='text-white'>Contact Us</h2>
                            <div className='row align-items-center pt-3'>
                                <div className='col-xl-1 col-lg-12 col-md-2 pb-2'>
                                    <img src={pin} alt="pin" />
                                </div>
                                <div className='col-xl-11 col-lg-12 col-md-10'>
                                    <h3 className='text-white'>Address</h3>
                                    <p className='text-white text-wrap mb-0'>15955 Formosa Lane Brandywine MD 20613</p>
                                </div>
                            </div>
                            <div className='row align-items-center pt-3'>
                                <div className='col-xl-1 col-lg-12 col-md-2 pb-2'>
                                    <img src={phone} alt="phone" />
                                </div>
                                <div className='col-xl-11 col-lg-12 col-md-10'>
                                    <h3 className='text-white'>Phone</h3>
                                    <a href="tel:+1240-640-7386" className='text-secondary text-wrap'>+1 240-640-7386</a>
                                </div>
                            </div>
                            <div className='row align-items-center pt-3 pb-2'>
                                <div className='col-xl-1 col-lg-12 col-md-2'>
                                    <img src={email} alt="email" />
                                </div>
                                <div className='col-xl-11 col-lg-12 col-md-10'>
                                    <h3 className='text-white'>Email</h3>
                                    <a href="mailto:support@heroselfapp.com" className='text-secondary text-wrap'>support@heroselfapp.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactus
