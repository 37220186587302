import React, { useEffect } from 'react';
import Slider from "react-slick";
import Clientprofile2 from '../../assets/images/home/client-profile-2.jpg';
import Clientprofile1 from '../../assets/images/home/client-profile.jpg';
import Quote from '../../assets/images/home/dialog.png';

const Userlove = () => {


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 2,
        variableWidth: true,
        centerMode: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {

                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1200,

            },


            {
                breakpoint: 576,
                settings: {
                    dots: false,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    dots: false,
                }
            },

        ]

    };
    return (
        <div className='userlove-section bg-primary'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div data-aos="fade-up">
                            <h1 className='text-white h1-regular text-center pb-3'>User Love for Heroself</h1>
                            <hr className='under-line-custom-hr mx-auto bg-secondary' />
                            <p className='text-white text-center mt-5'>See what our users have to say about
                                their Heroself experience</p>
                        </div>
                    </div>
                </div>
                <div className="slider-container" data-aos="fade-down">
                    <Slider {...settings}>
                        <div className="slide-box">
                            <p className='text-white'>Heroself makes social media exciting again. Highly recommend!</p>
                            <div className='row pt-2 pb-2 align-items-center'>
                                <div className='col-md-10 client-profile'>
                                    <img src={Clientprofile1} alt="Clientprofile1" />
                                    <h3 className='text-secondary'>Sarah Morgan</h3>
                                </div>
                                <div className='col-md-2 quote'>
                                    <img src={Quote} alt="Quote" />
                                </div>
                            </div>
                        </div>
                        <div className="slide-box">
                            <p className='text-white'>Heroself makes social media exciting again. Highly recommend!</p>
                            <div className='row pt-2 pb-2 align-items-center'>
                                <div className='col-md-10 client-profile'>
                                    <img src={Clientprofile2} alt="Clientprofile2" />
                                    <h3 className='text-secondary'>Mark Lewis</h3>
                                </div>
                                <div className='col-md-2 quote'>
                                    <img src={Quote} alt="Quote" />
                                </div>
                            </div>
                        </div>
                        <div className="slide-box">
                            <p className='text-white'>Heroself makes social media exciting again. Highly recommend!</p>
                            <div className='row pt-2 pb-2 align-items-center'>
                                <div className='col-md-10 client-profile'>
                                    <img src={Clientprofile2} alt="Clientprofile2" />
                                    <h3 className='text-secondary'>Mark Lewis</h3>
                                </div>
                                <div className='col-md-2 quote'>
                                    <img src={Quote} alt="Quote" />
                                </div>
                            </div>
                        </div>
                        <div className="slide-box">
                            <p className='text-white'>Heroself makes social media exciting again. Highly recommend!</p>
                            <div className='row pt-2 pb-2 align-items-center'>
                                <div className='col-md-10 client-profile'>
                                    <img src={Clientprofile2} alt="Clientprofile2" />
                                    <h3 className='text-secondary'>Mark Lewis</h3>
                                </div>
                                <div className='col-md-2 quote'>
                                    <img src={Quote} alt="Quote" />
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Userlove
