import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { apiPost } from "../../Util/ApiRequest";
import { ENDPOINTS } from "../../Util/EndPoint";
import Liked from "./Liked";
import LikedAlterEgo from "./LikedAlterEgo";
import Loader from "./Loader";

const PostLikes = ({ profileTypePro, userIdPro, postIdPro, postLikeTypePro }) => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [items, setItem] = useState([]);
  useEffect(() => {
    const url = "";
    setCurrentUrl(url);
    getAllLikeWithPagination(url);
  }, []);

  const handlePageClick = (item) => {
    const url = `?page=${item.selected + 1}&limit=10`;
    getAllLikeWithPagination(url);
  };


  const getAllLikeWithPagination = (url) => {
    let body = {
      post_like_type: postLikeTypePro,
      post_id: postIdPro,
      user_id: userIdPro,
      profile_type: profileTypePro,
    };
    setLoader(true);
    apiPost(
      ENDPOINTS.PaginatedLikedPost + url,
      body,
      (res) => {
        setLoader(false);
        setItem(res?.data?.results);
        setTotalPage(res?.data?.meta?.totalPages);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  return (
    <>
      <div className="border border-primary">
        <div className="table-main-header p-2">
          <h5 className="text-white my-auto p-2">Post likes</h5>
        </div>


        {profileTypePro == "normal_profile" && items?.length === 0 && (
          <h6 className='text-center mt-2'>likes not found</h6>
        )}

        {profileTypePro == "alter_ego_profile" && items?.length === 0 && (
          <h6 className='text-center mt-2'>likes not found</h6>
        )}



        {profileTypePro === "normal_profile" && (
          items?.length > 0 &&
          items.map((item, index) => (
            <Liked itemPro={item} key={index} />
          ))

        )}
        {profileTypePro === "alter_ego_profile" && (
          items?.length > 0 &&
          items.map((item, index) => (
            <LikedAlterEgo itemPro={item} key={index} />
          ))
        )}

        <ReactPaginate
          className="pagination-custom"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={totalPage}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
      <div className="">{loader && <Loader />}</div>
    </>
  );
};

export default PostLikes;
