import React from "react";
import Avatar from 'react-avatar';
import { BASE_IMG_URL } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";
const UserAlterEgoProfile = ({ user }) => {
  return (

    <>


        <div className="d-flex g-3  p-2 ">
          <Avatar className="rounded-2" title="asdsad" size={"50"} src={user?.profile_alter_ego?.alter_ego_profile_meta?.profile_image ? BASE_IMG_URL + user?.profile_alter_ego?.alter_ego_profile_meta?.profile_image : CatImg} />
          <div className="ms-2 align-self-center">
            <h6 className="text-gray-800 mb-0">{user?.profile_alter_ego?.user_name}</h6>
            <p className="text-gray-800 mb-0">{user?.email}</p>
          </div>
        </div>

    </>



  )
}
export default UserAlterEgoProfile