import React, { useEffect } from 'react'
import startedscreen from '../../assets/images/home/started-screen.png';
import download from '../../assets/images/home/download.png';
import Api from '../../assets/images/home/Api.png';
import user from '../../assets/images/home/user.png';


const Getstartedtoday = () => {


  return (
    <div className='getstarted'>
      <div className='container'>
        <div className='row align-items-center embrace-section-row'>
          <div className='col-md-6' >
            <div data-aos="fade-up">
              <img src={startedscreen} alt='startedscreen' />
            </div>
          </div>
          <div className='col-md-6' >
            <div data-aos="fade-down">
              <h1 className='text-primary h1-regular mt-2'>Get Started Today!</h1>
              <hr className='under-line-custom-hr bg-primary' />
              <p className='text-primary text-wrap'>Begin your journey of self-expression with Heroself. Download the app,
                effortlessly create your dual profiles, and engage instantly. Embrace the full spectrum of your
                identity with Heroself’s intuitive platform.</p>
              <div className='row mt-3'>
                <div className='col-xl-1 col-lg-12 col-md-2'>
                  <div className='started-box'>
                    <img src={download} alt='download' />
                  </div>
                </div>
                <div className='col-xl-11 col-lg-12 col-md-10'>
                  <div className='started-box'>
                    <h3>Download Heroself</h3>
                    <p>Access the app from the App Store and Google Play Store</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-1 col-lg-12 col-md-2'>
                  <div className='started-box'>
                    <img src={user} alt='user' />
                  </div>
                </div>
                <div className='col-xl-11 col-lg-12 col-md-10'>
                  <div className='started-box'>
                    <h3>Create Dual Profiles</h3>
                    <p>Customize your primary self and alter ego in seconds</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-1 col-lg-12 col-md-2'>
                  <div className='started-box'>
                    <img src={Api} alt='Api' />
                  </div>
                </div>
                <div className='col-xl-11 col-lg-12 col-md-10'>
                  <div className='started-box'>
                    <h3>Start Engaging</h3>
                    <p>Dive into posting, sharing, and connecting seamlessly</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Getstartedtoday
