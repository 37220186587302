import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import { isLoaderState, snakeBarState } from "./Util/RecoilStore";
import Loader from "./components/global/Loader";
import SnackBar from "./components/global/SnackBar";
import AdminLayout from "./components/layout/AdminLayout";
import "./custom.scss";
import BlockUserAlterEgo from "./pages/BlockUserAlterEgo";
import BlockUserNormal from "./pages/BlockUserNormal";
import Dashboard from "./pages/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import EventNormal from "./pages/EventNormal";
import GroupsAlterEgo from "./pages/GroupsAlterEgo";
import GroupsNormal from "./pages/GroupsNormal";
import Login from "./pages/Login";
import PostAlterEgo from "./pages/PostAlterEgo";
import PostDetailsAlterEgo from "./pages/PostDetailsAlterEgo";
import PostDetailsNormal from "./pages/PostDetailsNormal";
import PostNormal from "./pages/PostNormal";
import ReportedPostAlterEgo from "./pages/ReportedPostAlterEgo";
import ReportedPostNormal from "./pages/ReportedPostNormal";
import Users from "./pages/Users";
import UsersDetailAlterEgoProfile from "./pages/UsersDetailAlterEgoProfile";
import UsersDetailNormalProfile from "./pages/UsersDetailNormalProfile";
import EventAlterEgo from "./pages/EventAlterEgo";
import GroupDetailNormalProfile from "./pages/GroupDetailNormalProfile";
import EventDetailNormalProfile from "./pages/EventDetailNormalProfile";
import EventDetailAlterEgo from "./pages/EventDetailAlterEgo";
import GroupDetailAlterEgo from "./pages/GroupDetailAlterEgo";
import Home from "./pages/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import 'aos/dist/aos.css';
import AOS from 'aos';


function App() {

  AOS.init({
    duration: 1000, 
    easing: 'ease-in-out', 
    once: false, 
  });


  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

  return (
    <>
      {isLoaderInfo && <Loader />}

      <div className="App">
        <Router>
          <Routes>
          <Route exact path={``} element={<Home />} />
            <Route exact path={`/terms-and-conditions`} element={<TermsAndConditions />} />
            <Route exact path={`/privacy-policy`} element={<PrivacyPolicy />} />
            <Route exact path={`/login`} element={<Login />} />
            <Route path={`admin`} element={<AdminLayout />}>
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route exact path="user-detail-normal/:id" element={<UsersDetailNormalProfile />} />
              <Route exact path="user-detail-alter-ego/:id" element={<UsersDetailAlterEgoProfile />} />
              <Route exact path="post-detail-normal" element={<PostDetailsNormal />} />
              <Route exact path="post-detail-alter-ego/:id" element={<PostDetailsAlterEgo />} />
              <Route exact path="reported-post-normal" element={<ReportedPostNormal />} />
              <Route exact path="reported-post-alter-ego" element={<ReportedPostAlterEgo />} />
              <Route exact path="block-user-normal" element={<BlockUserNormal />} />
              <Route exact path="block-user-alter-ego" element={<BlockUserAlterEgo />} />
              <Route exact path="posts-alter-ego" element={<PostAlterEgo />} />
              <Route exact path="posts-normal" element={<PostNormal />} />
              <Route exact path="groups-alter-ego" element={<GroupsAlterEgo />} />
              <Route exact path="group-detail-alter-ego/:id" element={<GroupDetailAlterEgo />} />
              <Route exact path="groups-normal" element={<GroupsNormal />} />
              <Route exact path="group-detail-normal/:id" element={<GroupDetailNormalProfile />} />
              <Route exact path="event-normal" element={<EventNormal />} />
              <Route exact path="event-detail-normal/:id" element={<EventDetailNormalProfile />} />
              <Route exact path="event-alter-ego" element={<EventAlterEgo />} />
              <Route exact path="event-detail-alter-ego/:id" element={<EventDetailAlterEgo />} />
              <Route exact path="users" element={<Users />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
      <SnackBar
        closeSnackPro={() => setSnackBarInfo({ snackStatus: false, snackColor: '', snackMsg: '' })}
        snackInfoPro={snackBarInfo}
      />
    </>
  );
}

export default App;
