import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaArrowUp, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { apiPost } from '../Util/ApiRequest';
import { BASE_IMG_URL, ENDPOINTS } from '../Util/EndPoint';
import CoverImg from "../assets/images/cover-img.png";
import CatImg from "../assets/images/user.jpg";
// cover-img
import "../assets/pages-css/user-detail.css";
import CustomTags from '../components/global/CustomTags';
import CustomTooltip from '../components/global/CustomTooltip';
import FriendList from '../components/global/FriendList';
import SliderWithTitle from '../components/global/SliderWithTitle';
import MyPosts from './../components/global/MyPosts';
import UserAvatarNormal from '../components/global/UserAvatarNormal';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../components/global/TableComponent';
import { ProfileTypeEnum, removeUnderscore } from '../Util/Helper';
import ImageAvatar from '../components/global/ImageAvatar';
import UserGallery from '../components/global/UserGallery';


const EventDetailNormalProfile = () => {
  const [eventId, setEventId] = useState(0);
  const [loader, setLoader] = useState(false);


  const navigate = useNavigate();

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px'
    },
    {
      name: 'User',
      selector: (row) => <UserAvatarNormal user={row?.user} />,
      sortable: false,
      wrap: true,
      maxWidth: '400px',
      minWidth: '250px'
    },
    {
      name: 'Like Type',
      selector: (row) => removeUnderscore(row?.like_type),
      sortable: false,
    },

    {
      name: 'Event Date',
      selector: (row) => moment(row?.event_date).format("YYYY-MM-DD hh:mm:ss a"),
      sortable: false,
    },

    {
      name: 'Created At',
      selector: (row) => moment(row?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      sortable: false,
    },


  ];

  useEffect(() => {
    let eventIds = /[^/]*$/.exec(window.location.href)[0];
    setEventId(eventIds);
  }, [eventId]);



  return (
    <>
      <div className='main-profile-box'>

        <div className=' user-profile-main'>
          <h3 className='text-primary'>Normal Profile Group </h3>

          <div className='mt-4  user-profile-nav-tabs'>
            <Tabs
              defaultActiveKey="events-interested"
              className="mb-3"
            >
              <Tab eventKey="events-interested" title="Interested User ">
                {eventId > 0 && (
                  <TableComponent
                    title="Event Details"
                    endPoint={ENDPOINTS.EventsDetailPaginated}
                    headers={columns}
                    searchObject={{
                      profile_type: ProfileTypeEnum.NormalProfile,
                      event_id: eventId,
                      user_type: 'interested'
                    }}

                  />
                )}
              </Tab>

              <Tab eventKey="events-going" title="On-Going User ">
                {eventId > 0 && (
                  <TableComponent
                    title="Event Details"
                    endPoint={ENDPOINTS.EventsDetailPaginated}
                    headers={columns}
                    searchObject={{
                      profile_type: ProfileTypeEnum.NormalProfile,
                      event_id: eventId,
                      user_type: 'going_to'
                    }}

                  />
                )}
              </Tab>


            </Tabs>
          </div>
        </div>
      </div >

    </>
  );
};

export default EventDetailNormalProfile;
