
export const CheckImageExtensionAll = (filename) => {
    const imgType = ['txt', 'docx', 'pdf', 'apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'webp', 'mp4', 'wewbm', 'ogg'];
    var fileType = filename['name'].split('.').pop();
    const acceptFiles = imgType.includes(fileType)
    return acceptFiles;
};



export const ProfileTypeEnum = {
    NormalProfile: 'normal_profile',
    AlterEgoProfile: 'alter_ego_profile'
};


export const ProfileTypeTextEnum = {
    NormalProfile: 'normal',
    AlterEgoProfile: 'alter ego'
};

export const removeUnderscore = (sting) => {
    return sting.replaceAll(/_/g, ' ');
}

