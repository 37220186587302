import React, { useEffect } from 'react';
import happiness from '../../assets/images/home/happiness.png';
import thumbsup from '../../assets/images/home/thumbs-up.png';
import management from '../../assets/images/home/management.png';
import rocket from '../../assets/images/home/rocket.png';


const Joindual = () => {
 


    return (
        <div className='join-dual-section'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <div data-aos="fade-down" >
                            <h1 className='text-primary pb-3 h1-regular'>Join the Dual Persona Revolution</h1>
                            <p className='text-primary'>Experience the freedom of self-expression with Heroself.
                                Download the app now and unlock the potential of embracing
                                your dual personas on a single platform. Unleash your
                                hero and villain within!</p>
                            <div className="app-button mt-2 pt-2">
                                <a href="#" className="play-store"></a>
                                <a href="#" className="app-store"></a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div data-aos="fade-up">
                            <div className='row'>
                                <div className='col-md-6 pe-1 mt-3'>
                                    <div className='black-box text-center'>
                                        <img src={rocket} alt='rocket' className='mb-2' />
                                        <h3 className='text-white mb-0'>800</h3>
                                        <p className='text-white mb-0'>Powerful Options</p>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <div className='black-box text-center'>
                                        <img src={management} alt='managment' className='mb-2' />
                                        <h3 className='text-white mb-0'>24/7</h3>
                                        <p className='text-white mb-0'>Support</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 pe-1 mt-3'>
                                    <div className='black-box text-center'>
                                        <img src={thumbsup} alt='thumbsup' className='mb-2' />
                                        <h3 className='text-white mb-0'>27+</h3>
                                        <p className='text-white mb-0'>Year Experience</p>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <div className='black-box text-center'>
                                        <img src={happiness} alt='happiness' className='mb-2' />
                                        <h3 className='text-white mb-0'>1,320+</h3>
                                        <p className='text-white mb-0'>Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Joindual
