import React, { useEffect } from 'react';
import logo from '../../assets/images/home/logo.png';
import banerscreen from '../../assets/images/home/baner-screen.png';
import { useNavigate } from 'react-router-dom';
import LogoCom from './LogoCom';

const Welcomes = () => {

    const navigate = useNavigate();


    return (
        <>
            <LogoCom />

            <div className='wellcome-box'>
                <div className='container '>
                    <div className='row mt-3'>
                        <div className='col-xl-6 col-lg-12 col-md-12 pr-10 text-xl-start text-lg-center  text-md-center text-sm-center'>
                            <div data-aos="fade-down" >
                                <h1 className='text-white mb-2 h1-large'>Embrace Your Dual <br /> Persona With <br />Heroself</h1>
                                <p className='text-white text-wrap'>Heroself welcomes you to a unique social media experience
                                    where  you can authentically express both your everyday self and your
                                    alter ego. Explore the duality within you with ease</p>
                                <div className='app-button mt-5 text-xl-start text-lg-center  text-md-center text-sm-center'>
                                    <a href="#" className='play-store'></a>
                                    <a href="#" className='app-store'></a>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-12 col-md-12  text-center'>
                            <div data-aos="fade-up" >
                                <img src={banerscreen} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>


    )
}

export default Welcomes
