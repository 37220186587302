import React, { useEffect } from 'react';
import logo from '../../assets/images/home/logo.png';
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';


const Footer = () => {

    const navigate = useNavigate();

    return (
        <div className='footer-sect bg-primary'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-4 col-lg-12 col-md-6'>
                        <div data-aos="fade-up">
                            <div className='fot-logo'>
                                <img src={logo} alt='logo' className='mb-3' />
                                <p className='text-white'>Embrace your dual persona on a single platform.
                                    Share moments, connect with others, and express your authentic self alongside
                                    your alter ego.</p>
                                <ul className='fot-ul'>
                                    <li>
                                        <a href="#" className='text-secondary'> <FaFacebookF /></a>
                                    </li>
                                    <li>
                                        <a href="#" className='text-secondary'> <FaTwitter /></a>
                                    </li>
                                    <li>
                                        <a href="#" className='text-secondary'> <FaLinkedin /></a>
                                    </li>
                                    <li>
                                        <a href="#" className='text-secondary'> <FaPinterestP /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-2 col-lg-12 col-md-6'>
                        <div data-aos="fade-down">
                            <h4 className='text-white mb-4'>Quick Links</h4>
                            <ul className="list-unstyled">
                                <li className='mb-2'>
                                    <p onClick={() => {
                                        navigate(`/privacy-policy`);
                                    }} className='text-white a-decuration-text'>  <span className='me-2 text-secondary'><FaChevronRight /></span> Privacy Policy</p>
                                </li>
                                <li className='mb-2'>
                                    <p onClick={() => {
                                        navigate(`/terms-and-conditions`);
                                    }} className='text-white a-decuration-text'><span className='me-2 text-secondary'><FaChevronRight /></span> Terms & Conditions </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-xl-3 col-lg-12 col-md-6'>
                        <div data-aos="fade-up">
                            <h4 className='text-white mb-4'>Get In Touch</h4>
                            <ul className="list-unstyled get-touch">
                                <li className='mb-3'>
                                    <span className='me-2 text-secondary'><FaMapMarkerAlt /></span>
                                    <a href="https://maps.app.goo.gl/Ese9GpkPL3TsD8H5A" target='_blank' className='text-white a-decuration-text'>15955 Formosa Lane Brandywine <br /> MD 20613</a>
                                </li>
                                <li className='mb-3'>
                                    <span className='me-2 text-secondary'><FaPhoneAlt /></span>
                                    <a href="tel:+1240-640-7386" className='text-white a-decuration-text'>+1240-640-7386</a>
                                </li>
                                <li className='mb-3'>
                                    <span className='me-2 text-secondary'><FaEnvelope /></span>
                                    <a href="mailto:support@heroselfapp.com" className='text-white a-decuration-text'>support@heroselfapp.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-xl-3 col-lg-12 col-md-6'>
                        <div data-aos="fade-down">
                            <h4 className='text-white mb-4'>Get App From Store</h4>
                            <ul className='fot-app-btn'>
                                <li>
                                    <a href="#" className='play-store'></a>
                                </li>
                                <li>
                                    <a href="#" className='app-store'></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer
