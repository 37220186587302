import moment from "moment";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { BASE_IMG_URL } from "../../Util/EndPoint";
import CatImg from "../../assets/images/user.jpg";
import CommentChildAlterEgo from "./CommentChildAlterEgo";

function CommentAlterEgo ({ itemPro }) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <div className="d-flex pt-6 comment-boxes">
        <div className="symbol symbol-45px me-2">
          <img src={itemPro?.user?.profile_alter_ego?.alter_ego_profile_meta?.profile_image ? BASE_IMG_URL + itemPro?.user?.profile_alter_ego?.alter_ego_profile_meta?.profile_image : CatImg} alt="profile" className='border border-primary' />
        </div>
        <div className="d-flex flex-column flex-row-fluid">
          <div className="d-flex align-items-center flex-wrap mb-0">
            <h6 className="text-gray-800 mb-0 me-2">{itemPro?.user?.profile_alter_ego?.user_name}</h6>
            <span className="text-gray-400 font-10">{moment(itemPro?.created_at).format("YYYY-MM-DD hh:mm:ss a")}</span>
          </div>
          {itemPro?.body && (
            <span className="text-gray-800 fs-7 fw-normal pt-0 font-12">{itemPro?.body}</span>
          )}
          {itemPro?.media != null && (
            <button className="btn btn-primary btn-sm mt-2 w-80-px" onClick={() => setShowModal(true)}>Show Media</button>
          )}

          {itemPro?.child_comments?.length > 0 &&
            itemPro?.child_comments.map((item, index) => (
              <CommentChildAlterEgo itemChildPro={item} key={index} />
            ))}
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          closeButton
          className="bg-primary text-white custom-modal-header"
        >
          <Modal.Title className="font-18">Comment  media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={BASE_IMG_URL + itemPro?.media} alt="profile" className='comments-media-img' />
        </Modal.Body>
      </Modal>
    </>

  );
}

export default CommentAlterEgo;
